import React, { useMemo, memo, useCallback, useContext, useState, useRef } from 'react';
import { ActivityIndicator, View, FlatList, RefreshControl } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { selectAccountLists, selectFilteredBooksForLists } from '../../selectors';
import { getListsApi, getListsInProgress } from '../../actions/getLists';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { MaterialCommunityIcons as MCIcons, MaterialIcons as MIcons } from '@expo/vector-icons';
import BookListHor from '../../components/BookListHor';
import BookListHorHeader from '../../components/BookListHorHeader';
import NoBookResults from '../../components/NoBookResults';
import IconPossessed from '../../components/IconPossessed';
import MenuList from '../../components/MenuList';
import MenuTrigger from '../../components/MenuTrigger';
import { SECONDARY_COLOR, DEFAULT_LISTS, MAIN_COLOR, PILL_TABS_HEIGHT } from '../../constants';
import { useIsLoading, useGetDataOnNavFocus, useInitialNumberToRender, useSearch, useLibraryScrollHandler } from '../../hooks';
import { IcoMoon } from '../../utils/customIcons';
import { sortListsAlphabetically, isLargeScreen, getListName, keyExtractor, noResultsForSearchLists, isWeb } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';
import ListsSearchContext from '../../context/ListsSearchContext';
import ListsScrollContext from '../../context/ListsScrollContext';
import Animated from 'react-native-reanimated';

const tBase = 'screens.lists';

const AnimatedFlatlist = Animated.createAnimatedComponent(FlatList);

const DEFAULT_LISTS_ICONS = {
    wish: <MCIcons name="star" color={SECONDARY_COLOR} size={ 23 } style={{ marginLeft: -1, marginTop: -2 }} />,
    borrowed: <IcoMoon name="download" color={SECONDARY_COLOR} />,
    lent: <IcoMoon name="upload" color={SECONDARY_COLOR} />,
    reading: <MCIcons name='bookmark' color={SECONDARY_COLOR} size={ 22 } style={{ marginLeft: -2, marginTop: -1 }} />,
    unread: <MCIcons name='bookmark-minus' color={SECONDARY_COLOR} size={ 25 } style={{ marginLeft: -3, marginTop: -2 }} />,
    read: <MCIcons name='bookmark-check' color={SECONDARY_COLOR} size={ 25 } style={{ marginLeft: -3, marginTop: -2 }} />,
    toRead: <MIcons name='watch-later' color={SECONDARY_COLOR} size={ 22 } style={{ marginLeft: -1, marginTop: -1 }}/>,
    possessed: <IconPossessed size={ 18 } iconSize={13} bgColor={SECONDARY_COLOR} style={{ marginLeft: 1, marginTop: 1 }}/>
}

export default function ListsScreen({ navigation }) {
    const dispatch = useDispatch();
    const lists = useSelector(selectAccountLists);
    const [refreshing, setRefreshing] = useState(false);
    const [initialNumToRender, onLayoutContainer] = useInitialNumberToRender(314);
    const {
        pattern, 
        filters, 
        filteredBooks:filteredListsBooks, 
        hasSearch,
        resetSearch
     } = useSearch(selectFilteredBooksForLists, ListsSearchContext);
    const sortedLists = useMemo(() => sortListsAlphabetically(lists), [lists]);
    const isGettingLists = useIsLoading(getListsInProgress);
    const getLists = (checkCaching=true, showRefreshing=false) => {
        if(!isGettingLists) {
            showRefreshing && setRefreshing(true)
            dispatch(getListsApi({ showLoading: false, checkCaching })).then(() => setRefreshing(false));
        }
    }
    useGetDataOnNavFocus(navigation, getLists)
    const onRefresh = () => getLists(false, true);
    if(noResultsForSearchLists(filteredListsBooks, hasSearch)) {
        return (
            <NoBookResults 
                tBase={tBase}
                pattern={pattern}
                filters={filters}
                resetSearch={resetSearch} />
        )
    }
    return (
        <View onLayout={onLayoutContainer} style={{ flex:1 }}>
            { initialNumToRender ?
                <Lists 
                    lists={sortedLists} 
                    hasSearch={hasSearch}
                    onRefresh={onRefresh} 
                    navigation={navigation}
                    initialNumToRender={initialNumToRender}
                    filteredListsBooks={filteredListsBooks}
                    refreshing={refreshing} />  :
                <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={ MAIN_COLOR } size="large" />
                </View>  
            }
        </View> 
    )
}

const Lists = ({ lists, onRefresh, hasSearch, refreshing, filteredListsBooks, navigation, initialNumToRender }) => {
    const ref = useRef(null);
    const getItemLayout = useCallback((data, index) => {
        const length = index ? 314 : 199;
        const offset = index ? 199 + ((index-1) * 314) : 0;
        return {length, offset, index}
    }, []);
    const renderItem = useCallback(({ item, index }) => (
        <List 
            key={item.id}
            index={index}
            list={item}
            books={filteredListsBooks[item.id]}
            navigation={navigation} />
    ), [filteredListsBooks]);
    const insets = useSafeAreaInsets();
    const paddingBottom = (() => {
        if(isLargeScreen()) return insets.bottom + 50;
        return 50;
    })();
    const windowSize = Math.min(initialNumToRender*5, 15);
    const data = useMemo(() => {
        return lists.filter(list => {
            return !hasSearch || filteredListsBooks[list.id].length
        })
    }, [lists, filteredListsBooks]);
    useScrollToTop(ref);
    const scrollHandler = useLibraryScrollHandler(ListsScrollContext);
    const paddingTop = isWeb() ? 0 : PILL_TABS_HEIGHT;
    return (
        <AnimatedFlatlist
            ref={ref}
            onScroll={scrollHandler}
            contentContainerStyle={{ flexGrow: 1, paddingBottom, paddingTop }}
            keyboardShouldPersistTaps="handled"
            keyExtractor={keyExtractor}
            data={data}
            renderItem={renderItem}
            refreshControl={
                <RefreshControl 
                    refreshing={refreshing} 
                    onRefresh={onRefresh}
                    progressViewOffset={PILL_TABS_HEIGHT} />
            }
            getItemLayout={getItemLayout}
            initialNumToRender={initialNumToRender}
            maxToRenderPerBatch={10}
            windowSize={windowSize} />
    )
}

export const List = memo(({ list, books, navigation, context, contextId, index }) => {
    const {t} = useContext(AppSettingsContext);
    const loggedUserId = useSelector(state => state.loggedUserId);
    const isOwner = loggedUserId === list.ownerId;
    function getListIcon(list) {
        if(DEFAULT_LISTS.includes(list.type)) {
            return DEFAULT_LISTS_ICONS[list.type];
        }
        return null
    }
    const navigateToList = useCallback(() => navigation.push('List', { listId: list.id }));
    const Menu = () => (
        <MenuList
            list={list}
            placement='auto'
            deletable={!DEFAULT_LISTS.includes(list.type)}
            navigation={navigation}
            TriggerButton={ () => <MenuTrigger /> } />
    )
    return (
        <View style={{ marginTop: 5 }}>
            <BookListHorHeader
                tBase={tBase} 
                Icon={getListIcon(list)}
                title={getListName(list, t)} 
                isPrivate={list.visibility == 'private'}
                count={list.bookIds.length} 
                isFirst={index === 0}
                Menu={isOwner ? Menu : null}
                onHeaderPress={navigateToList} />
            <BookListHor 
                listId={list.id}
                context={context}
                contextId={contextId}
                books={books} 
                onSeeAllPress={navigateToList}
                navigation={navigation} />
        </View>
    )
})