import React, { useCallback, memo, useContext, useState, useMemo, useRef } from 'react';
import { ActivityIndicator, View, FlatList, RefreshControl } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { selectAccountShelves, selectFilteredBooksForShelves } from '../../selectors';
import { getShelvesApi, getShelvesInProgress } from '../../actions/getShelves';
import { useIsLoading, useGetDataOnNavFocus, useInitialNumberToRender, useSearch, useLibraryScrollHandler } from '../../hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import BookListHor from '../../components/BookListHor';
import BookListHorHeader from '../../components/BookListHorHeader';
import NoBookResults from '../../components/NoBookResults';
import MenuShelf from '../../components/MenuShelf';
import MenuTrigger from '../../components/MenuTrigger';
import { MAIN_COLOR, PILL_TABS_HEIGHT } from '../../constants';
import Empty from '../../components/Empty';
import { isWeb, isLargeScreen, keyExtractor, noResultsForSearchShelves, isIOS } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';
import ShelvesSearchContext from '../../context/ShelvesSearchContext';
import ShelvesScrollContext from '../../context/ShelvesScrollContext';
import Animated from 'react-native-reanimated';

const tBase = 'screens.shelves';
const ADD_BOOK_SCREEN = isWeb() ? 'AddBookSearch' : 'Scan';

const AnimatedFlatlist = Animated.createAnimatedComponent(FlatList);

export default function ShelvesScreen({ navigation }) {
    const { t } = useContext(AppSettingsContext);
    const [refreshing, setRefreshing] = useState(false);
    const [initialNumToRender, onLayoutContainer] = useInitialNumberToRender(314);
    const dispatch = useDispatch();
    const shelves = useSelector(selectAccountShelves);
    const {
        pattern, 
        filters, 
        filteredBooks:filteredShelvesBooks, 
        hasSearch,
        resetSearch
     } = useSearch(selectFilteredBooksForShelves, ShelvesSearchContext);
     const isGettingShelves = useIsLoading(getShelvesInProgress);
    const getShelves = (checkCaching=true, showRefreshing=false) => {
        if(!isGettingShelves) {
            showRefreshing && setRefreshing(true)
            dispatch(getShelvesApi({ showLoading: false, checkCaching })).then(() => setRefreshing(false));
        }
    }
    useGetDataOnNavFocus(navigation, getShelves);
    const hasOneShelfAndNoBooks = shelves.length == 1 && shelves[0].bookIds.length == 0;
    const onRefresh = () => getShelves(false, true);
    if(hasOneShelfAndNoBooks) {
        return (
            <Empty 
                title={ t(`${tBase}.empty.title`) }
                body={ t(`${tBase}.empty.body`) }
                img={ require('../../assets/emptyLibrary.png') }
                primaryActionLabel={ t(`${tBase}.empty.addBooks`) }
                primaryActionOnPress={ () => navigation.navigate('Modal', { screen: ADD_BOOK_SCREEN }) } />
        )
    } else if(noResultsForSearchShelves(filteredShelvesBooks, hasSearch)) {
        return (
            <NoBookResults 
                tBase={tBase}
                pattern={pattern}
                filters={filters}
                resetSearch={resetSearch} />
        )
    }
    return (
        <View onLayout={onLayoutContainer} style={{ flex:1 }}>
            { initialNumToRender ?
                <Shelves 
                    shelves={shelves} 
                    onRefresh={onRefresh} 
                    hasSearch={hasSearch}
                    navigation={navigation}
                    initialNumToRender={initialNumToRender}
                    filteredShelvesBooks={filteredShelvesBooks}
                    refreshing={refreshing} /> :
                <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={ MAIN_COLOR } size="large" />
                </View> 
            }
        </View> 
    )
}

const Shelves = ({ shelves, onRefresh, refreshing, hasSearch, filteredShelvesBooks, navigation, initialNumToRender }) => {
    const ref = useRef(null);
    const getItemLayout = useCallback((data, index) => {
        const length = index ? 314 : 199;
        const offset = index ? 199 + ((index-1) * 314) : 0;
        return {length, offset, index}
    }, [])
    const renderItem = useCallback(({ item, index }) => (
        <Shelf 
            key={item.id}
            index={index}
            shelf={item}
            books={filteredShelvesBooks[item.id]}
            navigation={navigation} />
    ), [filteredShelvesBooks]);
    const insets = useSafeAreaInsets();
    const paddingBottom = (() => {
        if(isLargeScreen()) return insets.bottom + 50;
        return 50;
    })();
    const windowSize = Math.min(initialNumToRender*5, 15);
    const data = useMemo(() => {
        return shelves.filter(shelf => {
            return !hasSearch || filteredShelvesBooks[shelf.id].length
        })
    }, [shelves, filteredShelvesBooks])
    useScrollToTop(ref);
    const scrollHandler = useLibraryScrollHandler(ShelvesScrollContext);
    const paddingTop = isWeb() ? 0 : PILL_TABS_HEIGHT;
    return (
        <AnimatedFlatlist
            ref={ref}
            onScroll={scrollHandler}
            contentContainerStyle={{ flexGrow: 1, paddingTop, paddingBottom }}
            keyboardShouldPersistTaps="handled"
            keyExtractor={keyExtractor}
            scrollEventThrottle={16}
            data={data}
            renderItem={renderItem}
            refreshControl={
                <RefreshControl 
                    refreshing={refreshing} 
                    onRefresh={onRefresh}
                    progressViewOffset={PILL_TABS_HEIGHT} />
            }
            getItemLayout={getItemLayout}
            initialNumToRender={initialNumToRender}
            maxToRenderPerBatch={10}
            windowSize={windowSize} />
    )
}

export const Shelf = memo(({ shelf, books, index, navigation }) => {
    const navigateToShelf = useCallback(() => navigation.push('Shelf', { shelfId: shelf.id }), []);
    const loggedUserId = useSelector(state => state.loggedUserId);
    const isOwner = loggedUserId === shelf.ownerId;
    const Menu = () => (
        <MenuShelf
            shelf={shelf}
            navigation={navigation}
            TriggerButton={ () => <MenuTrigger /> } />
    )
    return (
        <View style={{ marginTop: 5 }}>
            <BookListHorHeader
                tBase={tBase} 
                title={shelf.name} 
                isPrivate={shelf.visibility == 'private'}
                count={books.length} 
                isFirst={index === 0}
                Menu={isOwner ? Menu : null}
                onHeaderPress={navigateToShelf} />
            <BookListHor 
                books={books} 
                shelfId={shelf.id}
                onSeeAllPress={navigateToShelf}
                navigation={navigation} />
        </View>
    )
})