import React, { memo, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import { SECONDARY_COLOR, GRAY_TEXT_PALE } from '../constants';
import { MaterialIcons as MIcons } from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';

function BookListHorHeader({ tBase, title, count, Icon, onHeaderPress, isFirst, isPrivate=false, Menu=null }) {
    const {t} = useContext(AppSettingsContext);
    return(
        <TouchableOpacity 
            activeOpacity={0.7}
            onPress={onHeaderPress}
            hitSlop={{ top: 10, bottom: 10, left: 0, right: 0 }}
            style={[styles.container, { marginTop: isFirst ? 0 : 15, paddingRight: Menu ? 10 : 15 }]}>
            { Icon && <View style={{ width: 28 }}>{ Icon }</View> }
            <MontSBold numberOfLines={1} text={title} style={styles.title} />
            <InterReg style={[styles.count, { marginRight: isPrivate ? 5 : 'auto'}]} text={`(${count})`} />
            { isPrivate && <MIcons name='lock' style={{ marginRight: 'auto', marginTop: 2 }} size={16} color={SECONDARY_COLOR} /> }
            <View style={{ marginLeft: 15, flexDirection: 'row', alignItems: 'center'  }}> 
                <InterReg style={[styles.seeAll, { marginRight: Menu ? 10 : 0 }]} text={ t(`${tBase}.seeAll`)} />
                { Menu && Menu() }
            </View>
        </TouchableOpacity>
    )
}

export default memo(BookListHorHeader)

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        flex: 1, 
        flexDirection: 'row',
        minHeight: 22
    },
    title: {
        color: SECONDARY_COLOR, 
        flexShrink: 1, 
        fontSize: 16
    },
    count: { 
        color: GRAY_TEXT_PALE, 
        marginLeft: 8, 
        marginTop: 2, 
        fontSize: 14 
    },
    seeAll: {
        color: SECONDARY_COLOR, 
        fontSize: 16
    }
})