import React, { useMemo, useState, memo, useContext, useRef } from 'react';
import { View, ScrollView, StyleSheet, RefreshControl } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useScrollToTop } from '@react-navigation/native';
import { getActivityApi } from '../../actions/getActivity';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MontSBold from '../../components/MontserratBold';
import ActivityEmpty from '../../components/ActivityEmpty';
import ActivityAddedBooksByFollowing from '../../components/ActivityAddedBooksByFollowing';
import ActivityAddedBooksToGroup from '../../components/ActivityAddedBooksToGroup';
import ActivityAddedBooksToGroupCollection from '../../components/ActivityAddedBooksToGroupCollection';
import ActivityFollowedByUser from '../../components/ActivityFollowedByUser';
import ActivityNewMemberInGroup from '../../components/ActivityNewMemberInGroup';
import ActivityBookReview from '../../components/ActivityBookReview';
import ActivityReadingStatusSetToReading from '../../components/ActivityReadingStatusSetToReading';
import ActivityReadingStatusSetToRead from '../../components/ActivityReadingStatusSetToRead';
import { sortByDate, isDateToday, isDateThisWeek, isDateThisMonth, isLargeScreen } from '../../utils';
import { useGetDataOnNavFocus } from '../../hooks';
import { GRAY_LINE_COLOR, SECONDARY_COLOR } from '../../constants';
import Dayjs from 'dayjs';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.activity';

export default function ActivityScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const [refreshing, setRefreshing] = useState(false);
    const [isGettingActivity, setIsGettingActivity] = useState(false);
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const activity = useSelector(state => state.activity, shallowEqual);
    const getActivity = (checkCaching=true, showRefreshing=false) => { 
        if(!isGettingActivity) {
            setIsGettingActivity(true);
            showRefreshing && setRefreshing(true)
            dispatch(getActivityApi({ showLoading: false, checkCaching })).then(done); 
        }
    };
    const done = () => {
        setIsGettingActivity(false);
        setRefreshing(false);
    }
    const onRefresh = () => getActivity(false, true);
    
    useGetDataOnNavFocus(navigation, getActivity);

    const sortedActivity            = useMemo(() => sortByDate([...activity], 'date'), [activity]);
    const allActivityToday          = useMemo(() => sortedActivity.filter((item) => isDateToday(item.date), [sortedActivity]));
    const allActivityThisWeek       = useMemo(() => sortedActivity.filter((item) => isDateThisWeek(item.date) && !isDateToday(item.date), [sortedActivity]));
    const allActivityThisMonth      = useMemo(() => sortedActivity.filter((item) => isDateThisMonth(item.date) && !isDateThisWeek(item.date) && !isDateToday(item.date), [sortedActivity]));
    const allActivityEarlier        = useMemo(() => sortedActivity.filter((item) => !isDateThisMonth(item.date) && !isDateThisWeek(item.date) && !isDateToday(item.date), [sortedActivity]));
    const groupedActivityToday      = useMemo(() => groupActivities(allActivityToday), [allActivityToday]);
    const groupedActivityThisWeek   = useMemo(() => groupActivities(allActivityThisWeek), [allActivityThisWeek]);
    const groupedActivityThisMonth  = useMemo(() => groupActivities(allActivityThisMonth), [allActivityThisMonth]);
    const groupedActivityEarlier    = useMemo(() => groupActivities(allActivityEarlier), [allActivityEarlier]);

    function groupActivities(activities) {
        const addedBooksByFollowing = [];
        const addedBooksToGroup = [];
        const addedBooksToGroupCollection = [];
        const readingStatusSetToReading = [];
        const readingStatusSetToRead = [];
        const newFollowers = [];
        const newMemberInGroup = [];
        const newBookReview = [];
        for(const activity of activities) {
            if(activity.type == 'book_added_by_user') {
                const index = addedBooksByFollowing.findIndex(({userId, latest}) => (
                    activity.userId === userId && 
                    Dayjs(latest).subtract(7, 'day').isBefore(Dayjs(activity.date))
                ));
                if(index > -1) {
                    addedBooksByFollowing[index] = {
                        ...addedBooksByFollowing[index],
                        bookIds: [...addedBooksByFollowing[index].bookIds, activity.bookId],
                        earliest: activity.date
                    }
                } else {
                    addedBooksByFollowing.push({
                        type: 'book_added_by_user',
                        latest: activity.date,
                        earliest: activity.date,
                        bookIds: [activity.bookId],
                        userId: activity.userId
                    })
                }
            }
            if(activity.type == 'book_added_to_group') {
                const index = addedBooksToGroup.findIndex(({userId, groupId, latest}) => (
                    activity.userId === userId && 
                    activity.groupId === groupId &&
                    Dayjs(latest).subtract(7, 'day').isBefore(Dayjs(activity.date))
                ));
                if(index > -1) {
                    addedBooksToGroup[index] = {
                        ...addedBooksToGroup[index],
                        bookIds: [...addedBooksToGroup[index].bookIds, activity.bookId],
                        earliest: activity.date
                    }
                } else {
                    addedBooksToGroup.push({
                        type: 'book_added_to_group',
                        latest: activity.date,
                        earliest: activity.date,
                        bookIds: [activity.bookId],
                        userId: activity.userId,
                        groupId: activity.groupId
                    })
                }
            }
            if(activity.type == 'book_added_to_group_collection') {
                const index = addedBooksToGroupCollection.findIndex(({groupCollectionId, groupId, latest}) => (
                    activity.groupCollectionId === groupCollectionId && 
                    activity.groupId === groupId &&
                    Dayjs(latest).subtract(7, 'day').isBefore(Dayjs(activity.date))
                ));
                if(index > -1) {
                    addedBooksToGroupCollection[index] = {
                        ...addedBooksToGroupCollection[index],
                        bookIds: [...addedBooksToGroupCollection[index].bookIds, activity.bookId],
                        earliest: activity.date
                    }
                } else {
                    addedBooksToGroupCollection.push({
                        type: 'book_added_to_group_collection',
                        latest: activity.date,
                        earliest: activity.date,
                        bookIds: [activity.bookId],
                        groupCollectionId: activity.groupCollectionId,
                        groupId: activity.groupId
                    })
                }
            }
            if(activity.type == 'new_follower') {
                newFollowers.push({
                    ...activity,
                    latest: activity.date,
                    earliest: activity.date,
                })
            }
            if(activity.type == 'new_member_in_group') {
                newMemberInGroup.push({
                    ...activity,
                    latest: activity.date,
                    earliest: activity.date,
                })
            }
            if(activity.type == 'new_book_review') {
                newBookReview.push({
                    ...activity,
                    latest: activity.date,
                    earliest: activity.date,
                })
            }
            if(activity.type == 'reading_status_reading') {
                readingStatusSetToReading.push({
                    ...activity,
                    latest: activity.date,
                    earliest: activity.date,
                })
            }
            if(activity.type == 'reading_status_read') {
                readingStatusSetToRead.push({
                    ...activity,
                    latest: activity.date,
                    earliest: activity.date,
                })
            }
        }
        return sortByDate([
            ...addedBooksByFollowing, 
            ...addedBooksToGroup, 
            ...addedBooksToGroupCollection,
            ...newFollowers, 
            ...newMemberInGroup, 
            ...newBookReview, 
            ...readingStatusSetToReading,
            ...readingStatusSetToRead], 
            'latest'
        );
    }
    function renderActivities(activities) {
        return activities.map((activityItem, index) => renderActivityItems(activityItem, (activities.length-1) == index, index));
    }
    function renderActivityItems(activityItem, isLast, index) {
        switch (activityItem.type) {
            case 'book_added_by_user':
                return (
                    <ActivityAddedBooksByFollowing
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'book_added_to_group':
                return (
                    <ActivityAddedBooksToGroup
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'new_follower':
                return (
                    <ActivityFollowedByUser
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'new_member_in_group':
                return (
                    <ActivityNewMemberInGroup
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'new_book_review':
                return (
                    <ActivityBookReview
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'reading_status_reading':
                return (
                    <ActivityReadingStatusSetToReading
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'reading_status_read':
                return (
                    <ActivityReadingStatusSetToRead
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            case 'book_added_to_group_collection':
                return (
                    <ActivityAddedBooksToGroupCollection
                        key={index}
                        isLast={isLast} 
                        navigation={navigation} 
                        activityItem={activityItem} />
                )
            default:
                return null;
        }
    }
    const showToday = !!groupedActivityToday.length;
    const showThisWeek = !!groupedActivityThisWeek.length;
    const showThisMonth = !!groupedActivityThisMonth.length;
    const showEarlier = !!groupedActivityEarlier.length;
    const paddingBottom = (() => {
        if(isLargeScreen()) return insets.bottom + 50;
        return 50;
    })();
    const hasActivities = showToday || showThisWeek || showThisMonth || showEarlier;
    const ref = useRef(null);
    useScrollToTop(ref);
    return (
        <View style={{ flex: 1 }}>
            { hasActivities ?
                <ScrollView 
                    ref={ref}
                    contentContainerStyle={{ paddingTop: 20, paddingBottom }}
                    style={{ flex:1 }}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        /> }>
                    <Title title={t(`${tBase}.today`)} show={showToday} first={showToday} />
                    { renderActivities(groupedActivityToday) }
                    <Title title={t(`${tBase}.thisWeek`)} show={showThisWeek} first={!showToday && showThisWeek} />
                    { renderActivities(groupedActivityThisWeek) }
                    <Title title={t(`${tBase}.thisMonth`)} show={showThisMonth} first={!showToday && !showThisWeek && showThisMonth} />
                    { renderActivities(groupedActivityThisMonth)}
                    <Title title={t(`${tBase}.earlier`)} show={showEarlier} first={!showToday && !showThisWeek && !showThisMonth} />
                    { renderActivities(groupedActivityEarlier) } 
                </ScrollView> :
                <ActivityEmpty tBase={tBase} navigation={navigation} />
            }
        </View>
    )
}

const Title = memo(({ title, show, first=false }) => {
    if(!show) return null;
    return (
        <View style={[styles.title, { marginTop: first ? 10 : 20 }]}>
            <MontSBold text={title} style={{ fontSize: 15, textTransform: 'uppercase', color: SECONDARY_COLOR}} />
        </View>
    )
})

const styles = StyleSheet.create({
    title: {
        paddingLeft: 15,
        paddingBottom: 7,
        marginBottom: 15,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: GRAY_LINE_COLOR
    }
})