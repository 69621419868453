import { useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react';
import * as Sharing from 'expo-sharing';
import Dayjs from 'dayjs';
import { Dimensions } from 'react-native';
import { getStateFromPath } from '@react-navigation/native';
import linkingConfig from '../navigation/LinkingConfiguration'; 
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setInitialLoadPath } from '../actions';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';
import { getGroupProductsApi } from '../actions/getGroupProducts';
import { getAccountProductsApi } from '../actions/getAccountProducts';
import { askForFeedback } from '../utils/alerts';
import * as StoreReview from 'expo-store-review';
import i18n from '../utils/i18n';
import { 
    makeSelectExistingDiscussion, 
    selectBorrowRequests,
    selectGroupProductFromId, 
    selectAccountProductFromId,
    makeSelectUserUsingId,
    makeSelectGroupUsingId,
    makeSelectGroupsUsingIds,
    makeSelectBookUsingId,
    makeSelectBooksUsingIds,
    makeSelectUserShelves,
    makeSelectUserLists,
    makeSelectGroupRequestByMe,
    makeSelectGroupBookAttributes,
    makeSelectGroupBookAttributesForGroup,
    makeSelectBookBorrowRequestByMe,
    makeSelectCurrentBookLend,
    makeSelectBookReviewByMe,
    makeSelectHighestBookReview,
    makeSelectTagsForBookReview,
    makeSelectBookLikesOfBook,
    makeSelectKeywordsForBook,
    makeSelectReviewsForBook,
    makeSelectReview,
    makeSelectDiscussionUsingId,
    makeSelectShelfUsingId,
    makeSelectMessagesForDiscussion,
    makeSelectFollowRequestFromUserId,
    makeSelectSortedShelfBooks,
    makeSelectListUsingId,
    makeSelectSortedListBooks,
    makeSelectUsersUsingIds,
    selectAccountShelves,
    selectAccountLists,
    selectAccount,
    makeSelectGroupCollections,
    makeSelectGroupCollectionById,
    makeSelectGroupCollectionBooks,
    makeSelectKeywordsFromBooks,
    selectUnreadFollowRequests,
    selectUnreadMessageCount,
    selectUnreadGroupRequests,
    selectUnreadGroupInvitations,
    selectUnreadBorrowRequests,
    selectUnreadLends,
    selectUnreadLendReturnRequests,
    selectUnreadBorrowRequestsAccepted,
    selectUnreadLendReturnsIndicatedByBorrower,
    selectUnreadConsiergeBorrows,
    makeSelectUserKeywordUsingId,
    makeSelectBookCOuntWithUserKeyword,
    selectLending
} from '../selectors';
import { createDiscussionApi } from '../actions/createDiscussion';
import { 
    removeGroupFromStore, 
    removeBookFromStore,
    removeListFromStore,
    removeShelfFromStore,
    removeGroupCollectionFromStore
} from '../actions';
import { setHasSeenApi } from '../actions/setHasSeen';
import { 
    isInModal, 
    getCanonicalPath, 
    isWeb, 
    getRouteDetailsFromStateFromPath, 
    isNative, 
    openModalOrPushScreen, 
    deepCopy, 
    bookFilterIsEmpty,
    getBookFilterCountDetail,
    matchBooksByPatternAndFilters
} from '../utils';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
    MODALS_TO_NOT_OPEN_ON_PAGE_LOAD, 
    MODALS_OPENABLE_BY_ACTION_URL_PARAM,
    COLUMN_NBR_RANGES_BOOK_ITEM, 
    COLUMN_NBR_RANGES_GROUP_ITEM, 
    COLUMN_NBR_RANGES_USER_ITEM, 
    COLUMN_NBR_RANGES_LEND_ITEM,
    PUBLIC_ROUTE_NAMES,
    BOOK_STATUS_LISTS,
    BOOK_FILTER_DEFAULT,
    PILL_TABS_SCROLL_HIDE_THRESHOLD,
    PILL_TABS_HEIGHT
} from '../constants';

import { useAnimatedScrollHandler, useSharedValue, runOnJS, useAnimatedStyle, withTiming } from 'react-native-reanimated';

import AppSettingsContext from '../context/AppSettingsContext';

export function useHandleInitialLoadPath(navigationRef, setInitalLoadPathUsed) {
    const dispatch = useDispatch();
    const initialLoadPath = useSelector(state => state.settings.initialLoadPath, shallowEqual);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    useEffect(() => {
        const stateFromPath = initialLoadPath ? getStateFromPath(initialLoadPath, linkingConfig.config) : undefined;
        if(loggedUserId && initialLoadPath && stateFromPath && navigationRef.current) {
            if(stateFromPath?.routes[0]?.name == 'Modal') {
                const { name:screen, params={} } = stateFromPath.routes[0].state.routes[0];
                if(!MODALS_TO_NOT_OPEN_ON_PAGE_LOAD.includes(screen)) {
                    setInitalLoadPathUsed(true);
                    navigationRef.current.navigate('Modal', { screen, params });
                }
            } else {
                setInitalLoadPathUsed(true);
                const { routeName, routeParams } = getRouteDetailsFromStateFromPath(stateFromPath);
                if(PUBLIC_ROUTE_NAMES.includes(routeName)) {
                    navigationRef.current.reset({
                        "index": 0,
                        "routeNames": [
                            "Home",
                            "BooksList",
                            "Book",
                            "GroupBook",
                            "Shelf",
                            "List",
                            "User",
                            "Users",
                            "Followers",
                            "Following",
                            "Group",
                            "GroupMembers",
                            "GroupCollection"
                        ],
                        "routes": [
                            {
                                "name": routeName,
                                "params": routeParams
                            }
                        ]
                    });
                } else {
                    navigationRef.current.reset(stateFromPath)
                }
            }
            dispatch(setInitialLoadPath(null));
        }
    }, [initialLoadPath, loggedUserId, navigationRef])
}

export function useLinkPseudos(ref, enable=true) {
    const isHovered = useHover(ref);
    const isFocused = useFocus(ref);
    const isActive = useActive(ref);
    const showHover = isHovered && enable;
    const showFocused = isFocused && enable;
    const showActive = isActive && enable;
    return [showHover, showFocused, showActive]
}

export function useGetBookItemLayout(width, containerHorSpacing, itemHorSpacing) {
    return useGetItemLayout(
        width, 
        containerHorSpacing, 
        itemHorSpacing, 
        COLUMN_NBR_RANGES_BOOK_ITEM, 
        2
    )
}

export function useGetGroupItemLayout(width, containerHorSpacing, itemHorSpacing) {
    return useGetItemLayout(
        width, 
        containerHorSpacing, 
        itemHorSpacing, 
        COLUMN_NBR_RANGES_GROUP_ITEM, 
        1
    )
}

export function useGetUserItemLayout(width, containerHorSpacing, itemHorSpacing) {
    return useGetItemLayout(
        width, 
        containerHorSpacing, 
        itemHorSpacing, 
        COLUMN_NBR_RANGES_USER_ITEM, 
        1
    )
}

export function useGetLendItemLayout(width, containerHorSpacing, itemHorSpacing) {
    return useGetItemLayout(
        width, 
        containerHorSpacing, 
        itemHorSpacing, 
        COLUMN_NBR_RANGES_LEND_ITEM, 
        1
    )
}

function useGetItemLayout(width, containerHorSpacing, itemHorSpacing, colNbrRanges, defaultColNbr) {
    const calculateLayout = () => {
        let newWidth;
        let newCols;
        for(let colNbr in colNbrRanges) {
            const range = colNbrRanges[colNbr];
            if(range[0] <= width && range[1] >= width) {
                const totalMargins = (containerHorSpacing * 2) + (itemHorSpacing * colNbr)
                newCols = colNbr;
                const newItemWidth = Math.floor((width - totalMargins) / colNbr);
                newWidth = newItemWidth;
                break;
            }
        }
        return { newWidth, newCols }
    }
    const { newWidth, newCols } = calculateLayout();
    const [itemWidth, setItemWidth] = useState(newWidth);
    const [columns, setColumns] = useState(parseInt(newCols));
    useEffect(() => {
        if(width > 0) {
            const { newWidth, newCols } = calculateLayout();
            if(itemWidth != newWidth) setItemWidth(newWidth);
            if(columns != newCols) setColumns(parseInt(newCols));
        }
    }, [width])
    return { columns, itemWidth }
}

export function useStartDiscussion(navigation, userId) {
    const inModal = isInModal(navigation);
    const dispatch = useDispatch(); 
    const selectExistingDiscussion = useMemo(makeSelectExistingDiscussion, []);
    const existingDiscussion = useSelector(state => selectExistingDiscussion(state, { userId }), shallowEqual);
    function discussionCreated(json) {
        if(json?.success) { 
            navigateToDiscussion(json.discussion.id) 
        } else {
            __DEV__ && console.log('There was a problem! ', json);
        }
    }
    function navigateToDiscussion(discussionId) {
        if(inModal) {
            navigation.push('Discussion', { discussionId })
        } else {
            navigation.navigate('Modal', { screen: 'Discussion', params: { discussionId }}) 
        }
    }
    return () => {
        if(existingDiscussion?.id) {
             navigateToDiscussion(existingDiscussion.id)
        } else {
            dispatch(createDiscussionApi({ userId })).then(discussionCreated)
        }
    }
}

export function useGetBookEventsForDiscussion(discussion) {
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const borrowRequests = useSelector(selectBorrowRequests);
    const lends = useSelector(state => state.lends, shallowEqual);
    const userId = discussion?.userIds?.filter(userId => userId != loggedUserId)[0];
    const selectUserUsingId = useMemo(makeSelectUserUsingId, []);
    const correspondent = useSelector(state => selectUserUsingId(state, { userId }), shallowEqual)
    function getBookRequestsForDiscussion() {
        if(discussion) {
            if(borrowRequests.length && correspondent) {
                return borrowRequests.filter(borrowRequest => {
                    return (
                        (borrowRequest.fromId == correspondent.id && borrowRequest.toId == loggedUserId) || 
                        (borrowRequest.toId == correspondent.id && borrowRequest.fromId == loggedUserId)
                    )
                })
            }
        }
        return []
    }
    function getBookLendsForDiscussion() {
        if(discussion) {
            if(lends.length && correspondent) {
                return lends.filter(lend => {
                    return (
                        (lend.fromId == correspondent.id && lend.toId == loggedUserId) || 
                        (lend.toId == correspondent.id && lend.fromId == loggedUserId)
                    )
                })
            }
        }
        return []
    }
    const bookEvents = useMemo(() => {
        return getBookRequestsForDiscussion().concat(getBookLendsForDiscussion())
    }, [lends, borrowRequests])
    return bookEvents;
}

export function useUser(userId) {
    const selectUserUsingId = useMemo(makeSelectUserUsingId, []);
    return useSelector(state => selectUserUsingId(state, { userId }), shallowEqual)
}

export function useUsers(userIds) {
    const selectUsersFromUserIds = useMemo(makeSelectUsersUsingIds, []);
    return useSelector(state => selectUsersFromUserIds(state, { userIds }), shallowEqual);
}

export function useGroup(groupId) {
    const selectGroupUsingId = useMemo(makeSelectGroupUsingId, []);
    return useSelector(state => selectGroupUsingId(state, { groupId }), shallowEqual)
}

export function useGroups(groupIds) {
    const selectGroupsUsingIds = useMemo(makeSelectGroupsUsingIds, []);
    return useSelector(state => selectGroupsUsingIds(state, { groupIds }), shallowEqual);
}

export function useBook(bookId) {
    const selectBookUsingId = useMemo(makeSelectBookUsingId, []);
    return useSelector(state => selectBookUsingId(state, { bookId }), shallowEqual)
}

export function useBooks(bookIds) {
    const selectBooksUsingIds = useMemo(makeSelectBooksUsingIds, []);
    return useSelector(state => selectBooksUsingIds(state, { bookIds }), shallowEqual);
}

export function useUserShelves(userId) {
    const selectUserShelves = useMemo(makeSelectUserShelves, []);
    return useSelector(state => selectUserShelves(state, { userId }), shallowEqual);
}

export function useUserLists(userId) {
    const selectUserLists = useMemo(makeSelectUserLists, []);
    return useSelector(state => selectUserLists(state, { userId }), shallowEqual);
}

export function useGroupRequest(groupId) {
    const selectGroupRequestByMe = useMemo(makeSelectGroupRequestByMe, []);
    return useSelector(state => selectGroupRequestByMe(state, { groupId }), shallowEqual);
}

export function useGroupBookAttributes(groupId, bookId) {
    const selectGroupBookAttributes = useMemo(makeSelectGroupBookAttributes, []);
    return useSelector(state => selectGroupBookAttributes(state, { groupId, bookId }), shallowEqual);
}

export function useGroupBookAttributesForGroup(groupId) {
    const selectGroupBookAttributesForGroup = useMemo(makeSelectGroupBookAttributesForGroup, []);
    return useSelector(state => selectGroupBookAttributesForGroup(state, { groupId }), shallowEqual);
}

export function useBookRequestByMe(bookId) {
    const selectCurrentBookBorrowRequest = useMemo(makeSelectBookBorrowRequestByMe, []);
    return useSelector(state => selectCurrentBookBorrowRequest(state, { bookId }), shallowEqual);
}

export function useBookCurrentLend(bookId) {
    const selectCurrentBookLend = useMemo(makeSelectCurrentBookLend, []);
    return useSelector(state => selectCurrentBookLend(state, { bookId }), shallowEqual);
}

export function useFollowRequestsForUser(userId) {
    const selectFollowRequestFromUserId = useMemo(makeSelectFollowRequestFromUserId, []);
    return useSelector(state => selectFollowRequestFromUserId(state, { userId } ), shallowEqual);
}

export function useShelf(shelfId) {
    const selectShelfUsingId = useMemo(makeSelectShelfUsingId, []);
    return useSelector(state => selectShelfUsingId(state, { shelfId }), shallowEqual);
}

export function useShelfBooks(sort, bookIds) {
    const selectSortedShelfBooks = useMemo(makeSelectSortedShelfBooks, []);
    return useSelector(state => selectSortedShelfBooks(state, { sort, bookIds }), shallowEqual);
}

export function useList(listId) {
    const selectListUsingId = useMemo(makeSelectListUsingId, []);
    return useSelector(state => selectListUsingId(state, { listId }), shallowEqual);
}

export function useListBooks(sort, bookIds) {
    const selectSortedListBooks = useMemo(makeSelectSortedListBooks, []);
    return useSelector(state => selectSortedListBooks(state, { sort, bookIds }), shallowEqual);
}

export function useCanBorrowBook(book) {
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isOwner = book.ownerId === loggedUserId;
    const bookCurrentlyLent = useBookCurrentLend(book.id);
    const alreadyRequestedByMe = useBookRequestByMe(book.id);
    return !isOwner && book.lendable && book.possessed && !bookCurrentlyLent && !alreadyRequestedByMe;
}

export function useBookReviewByMe(bookId) {
    const selectBookReviewByMe = useMemo(makeSelectBookReviewByMe, []);
    return useSelector(state => selectBookReviewByMe(state, { bookId }), shallowEqual);
}

export function useBookReviewTags(bookReviewId) {
    const selectTagsForBookReview = useMemo(makeSelectTagsForBookReview, []);
    return useSelector(state => selectTagsForBookReview(state, { bookReviewId }), shallowEqual);
}

export function useIsLoading(actionStatesKey) {
    const actionType = actionStatesKey.toString();
    const isLoading = useSelector(state => state.loading.actionStates[actionType], (left, right) => right === left);
    return useMemo(() => isLoading === undefined ? false : isLoading, [isLoading]);
}

export function useBookReviewHeights(bookReviewIds) {
    const selectHighestBookReview = useMemo(makeSelectHighestBookReview, []);
    return useSelector(state => selectHighestBookReview(state, { bookReviewIds }), shallowEqual);
}

export function useBookLikes(bookId, providerBookId) {
    const selectBookLikesOfBook = useMemo(makeSelectBookLikesOfBook, []);
    return useSelector(state => selectBookLikesOfBook(state, { bookId, providerBookId }), shallowEqual);
}

export function useBookKeywords(bookId) {
    const selectKeywordsForBook = useMemo(makeSelectKeywordsForBook, []);
    return useSelector(state => selectKeywordsForBook(state, { bookId}), shallowEqual);
}

export function useBookReviews(bookId, providerBookId) {
    const selectReviewsForBook = useMemo(makeSelectReviewsForBook, []);
    return useSelector(state => selectReviewsForBook(state, { bookId, providerBookId }), shallowEqual);
}

export function useBookReview(reviewId) {
    const selectReview = useMemo(makeSelectReview, []);
    return useSelector(state => selectReview(state, { reviewId }), shallowEqual);
}

export function useDiscussion(discussionId) {
    const selectDiscussionUsingId = useMemo(makeSelectDiscussionUsingId, []);
    return useSelector(state => selectDiscussionUsingId(state, { discussionId }), shallowEqual);
}

export function useDiscussionMessages(discussionId) {
    const selectMessagesForDiscussion = useMemo(makeSelectMessagesForDiscussion, []);
    return useSelector(state => selectMessagesForDiscussion(state, { discussionId }), shallowEqual);
}

export function useGetDataOnNavFocus(navigation, getData) {
    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', getData);
        return unsubscribe;
    }, [])
}

export function useGroupCollections(collectionIds, canModify) {
    const selectGroupCollections = useMemo(makeSelectGroupCollections, []);
    return useSelector(state => selectGroupCollections(state, { collectionIds, canModify }), shallowEqual);
}

export function useGroupCollection(groupCollectionId) {
    const selectGroupCollection = useMemo(makeSelectGroupCollectionById, []);
    return useSelector(state => selectGroupCollection(state, { groupCollectionId }), shallowEqual);
}

export function useGroupCollectionBooks(groupCollectionId) {
    const selectGroupCollectionBooks = useMemo(makeSelectGroupCollectionBooks, []);
    return useSelector(state => selectGroupCollectionBooks(state, { groupCollectionId }), shallowEqual);
}

export function useKeywordsFromBooks(bookIds, sort='frequency') {
    const { locale } = useContext(AppSettingsContext);
    const selectKeywordsFromBooks = useMemo(makeSelectKeywordsFromBooks, []);
    return useSelector(state => selectKeywordsFromBooks(state, { bookIds, sort, locale }), shallowEqual);
}

export function useUserKeyword(userKeywordId) {
    const selectUserKeywordUsingId = useMemo(makeSelectUserKeywordUsingId, []);
    return useSelector(state => selectUserKeywordUsingId(state, { userKeywordId }), shallowEqual);
}

export function useUserKeywordBookCount(userKeywordId) {
    const selectBookCOuntWithUserKeyword = useMemo(makeSelectBookCOuntWithUserKeyword, []);
    return useSelector(state => selectBookCOuntWithUserKeyword(state, { userKeywordId }), shallowEqual);
}

export function useGroupProducts() {
    const dispatch = useDispatch();
    const products = useSelector(state => state.groupProducts);
    useEffect(() => {
        if(!products.length) dispatch(getGroupProductsApi())
    }, []);
    return products;
}

export function useGroupProduct(groupProductId) {
    const dispatch = useDispatch();
    const product = useSelector(state => selectGroupProductFromId(state, { groupProductId }));
    useEffect(() => {
        if(!product) dispatch(getGroupProductsApi())
    }, []);
    return product;
}

export function useAccountProducts() {
    const dispatch = useDispatch();
    const products = useSelector(state => state.accountProducts);
    useEffect(() => {
        if(!products.length) dispatch(getAccountProductsApi())
    }, []);
    return products;
}

export function useAccountProduct(accountProductId) {
    const dispatch = useDispatch();
    const product = useSelector(state => selectAccountProductFromId(state, { accountProductId }));
    useEffect(() => {
        if(!product) dispatch(getAccountProductsApi())
    }, []);
    return product;
}

export function useFlatListPadding() {
    const { width } = Dimensions.get('window');
    const paddingHorizontal = (() => {
        if(width > 500 && width < 700) return 5;
        if(width >= 700) return 10;
        return 0;
    })(); 
    const insets = useSafeAreaInsets();
    return { 
        paddingBottom: insets.bottom + 30,
        paddingTop: 30,
        paddingHorizontal
    }
}

export function useSetInitialPath(route) {
    const dispatch = useDispatch();
    const { path, params, name } = route;
    const initialLoadPath = useSelector(state => state.settings.initialLoadPath, shallowEqual);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const canonicalPath = path ? getCanonicalPath(name, params, path, true) : undefined;
    useEffect(() => {
        if(canonicalPath && isWeb() && !loggedUserId && initialLoadPath != canonicalPath) { 
            dispatch(setInitialLoadPath(canonicalPath));
        }
    },[])
}

export function usePromise(func, deps) {
    const [state, setState] = useState({
        state: 'pending',
    });
    useEffect(() => {
        let mounted = true;
        func().then((value) => {
            if (mounted)
                setState({ state: 'fulfilled', value: value });
        }, (error) => {
            if (mounted)
                setState({ state: 'rejected', error: error });
        });
        return () => {
            mounted = false;
        };
    }, deps);
    return state;
}

export function useBookFormDefaultValues(props) {
    const shelves = useSelector(selectAccountShelves);
    const lists = useSelector(selectAccountLists);
    const lastBookAdded = useSelector(state => state.settings.lastBookAdded, shallowEqual);
    const listIds = (() => {
        if(props?.listId && !props.shelfId) return [props.listId];
        if(lastBookAdded) {
            return lastBookAdded.listIds.filter(listId => {
                const list = lists.find(list => list.id === listId);
                return list && !BOOK_STATUS_LISTS.includes(list.type);
            });
        }
        return [];
    })()
    const shelfId = (() => {
        if(props?.shelfId) return props.shelfId;
        if(props?.listId) return null;
        if(lastBookAdded) return lastBookAdded.shelfId;
        return shelves[0].id;
    })()
    const possessed = (() => {
        if(props?.listId) return false;
        if(lastBookAdded) return lastBookAdded.possessed;
        return true;
    })()
    const readingStatus = (() => {
        if(props?.listId) return 'unread';
        if(lastBookAdded) return lastBookAdded.readingStatus;
        return 'read';
    })()
    return { shelfId, listIds, possessed, readingStatus };
}

export function useSearchShelfOrListBooks(books, isOwner, SearchContext) {
    const { searchPattern, filters, resetSearch } = useContext(SearchContext);
    const lending = useSelector(selectLending);
    const isFilterEmpty = bookFilterIsEmpty(filters);
    const matchedBooks = useMemo(() => {
        if(isOwner) {
            const { filteredBooks } = matchBooksByPatternAndFilters(books, searchPattern, filters, lending);
            return filteredBooks;
        }
        return books;
    }, [books, filters, searchPattern]);
    return {
        matchedBooks,
        hasSearch: !isFilterEmpty || searchPattern,
        resetSearch,
        pattern: searchPattern 
    };
}

export function useIsMounted() {
    const ref = useRef(true)
    useEffect(() => {
        return () => {
            ref.current = false
        }
    }, [])
    return useCallback(() => ref.current, [])
}

export function useWebShareApiAvailable() {
    const [canShareViaApi, setCanShareViaApi] = useState(false);
    useEffect(() => {
        async function check() {
            setCanShareViaApi(await Sharing.isAvailableAsync());
        }
        check();
    }, []);
    return canShareViaApi
}

export function useGetGroupDone(groupId) {
    const removeGroupFromStoreAction = removeGroupFromStore({ groupId });
    return useGetDone(removeGroupFromStoreAction);
}

export function useGetBookDone(bookId) {
    const removeBookFromStoreAction = removeBookFromStore({ bookId });
    return useGetDone(removeBookFromStoreAction);
}

export function useGetUserDone() {
    return useGetDone();
}

export function useGetListDone(listId) {
    const removeListFromStoreAction = removeListFromStore({ listId });
    return useGetDone(removeListFromStoreAction);
}

export function useGetShelfDone(shelfId) {
    const removeShelfFromStoreAction = removeShelfFromStore({ shelfId });
    return useGetDone(removeShelfFromStoreAction);
}

export function useGetGroupCollectionDone(groupCollectionId) {
    const removeGroupCollectionFromStoreAction = removeGroupCollectionFromStore({ groupCollectionId });
    return useGetDone(removeGroupCollectionFromStoreAction);
}

function useGetDone(removeFromStoreAction) {
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const [fetchStatus, setFetchStatus] = useState(null);
    function done(json) {
        let status = 'success';
        if(!json || (json && json.responseStatus == 400)) {
            status = 'failed';
        } else if(json.responseStatus == 404) {
            removeFromStoreAction && dispatch(removeFromStoreAction);
            status = 'notFound';
        } else if(json.responseStatus == 403) {
            status = 'notAllowed';
        } else if(json.cached) {
            status = 'cached'
        }
        isMounted() && setFetchStatus(status);
    }
    return [fetchStatus, done]
}

export function useAskForFeedback(navigation, t) {
    const lastBookAdded = useSelector(state => state.settings.lastBookAdded);
    const account = useSelector(selectAccount);
    const dispatch = useDispatch();
    const appIsGreat = () => {
        StoreReview.requestReview();
        dispatch(setHasSeenApi({ hasSeen: 'asked_to_review' }));
    }
    const appIsOk = () => {
        dispatch(setHasSeenApi({ hasSeen: 'shown_feedback_modal' }));
        navigation.navigate('Modal', { screen: 'AskForFeedback'});
    }
    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', handleOnFocus);
        return unsubscribe;
    }, [account?.hasSeen])
    const handleOnFocus = () => {
        const accountIsOverAWeekOld = Dayjs(account?.created).isBefore(Dayjs().subtract(1, 'week'));
        const bookWasAddedLessThan5MinutesAgo = Boolean(lastBookAdded?.created && Dayjs(lastBookAdded.created).isAfter(Dayjs().subtract(5, 'minute')));
        const userHasNotBeenAskedForFeedback = !account?.hasSeen?.includes('asked_for_feedback');
        if(isNative() && 
           account?.bookCount > 10 &&
           accountIsOverAWeekOld &&
           bookWasAddedLessThan5MinutesAgo &&
           userHasNotBeenAskedForFeedback) {
            setTimeout(() => askForFeedback(appIsOk, appIsGreat, dispatch, t), 1000);
            dispatch(setHasSeenApi({ hasSeen: 'asked_for_feedback' }));
        }
    };
}

export function useOpenPageLoadedAction(route, navigation, context, availableRequirements) {
    const loggedUserId = useSelector(state => state.loggedUserId);
    const [actionHandled, setActionHandled] = useState(false)
    const action = route?.params?.action;
    if(action) { 
        const actionConfig = MODALS_OPENABLE_BY_ACTION_URL_PARAM[action];
        if(loggedUserId &&
           actionConfig && 
           actionConfig?.context === context && 
           passedRequirements(actionConfig?.requirements, availableRequirements) && 
           !actionHandled) {
            const params = {};
            for(let paramKey of actionConfig.routeParams) {
                const param = route?.params[paramKey];
                if(param) {
                    params[paramKey] = param;
                }
            }
            setTimeout(() => {
                openModalOrPushScreen(navigation, action, params);
                setActionHandled(true);
            }, 500);
        }
    }
    function passedRequirements(requirements, availableRequirements) {
        if(!requirements) return true;
        for(let requirement of requirements) {
            if(!availableRequirements[requirement]) return false
        }
        return true;
    }
}

export function useUnreadNotifs() {
    const unreadFollowRequests = useSelector(selectUnreadFollowRequests, shallowEqual);
    const unreadMessagesCount = useSelector(selectUnreadMessageCount, shallowEqual);
    const unreadGroupRequests = useSelector(selectUnreadGroupRequests, shallowEqual);
    const unreadGroupInvitations = useSelector(selectUnreadGroupInvitations, shallowEqual);
    const unreadBorrowRequests = useSelector(selectUnreadBorrowRequests, shallowEqual);
    const unreadLends = useSelector(selectUnreadLends, shallowEqual);
    const unreadLendReturnRequests = useSelector(selectUnreadLendReturnRequests, shallowEqual);
    const unreadBorrowRequestsAccepted = useSelector(selectUnreadBorrowRequestsAccepted, shallowEqual);
    const unreadLendReturnsIndicatedByBorrower = useSelector(selectUnreadLendReturnsIndicatedByBorrower, shallowEqual);
    const unreadConsiergeBorrows = useSelector(selectUnreadConsiergeBorrows, shallowEqual);
    return {
        unreadLendsTabCount: unreadBorrowRequests.length + unreadBorrowRequestsAccepted.length + unreadLends.length + unreadLendReturnRequests.length + unreadLendReturnsIndicatedByBorrower.length + unreadConsiergeBorrows.length,
        unreadGroupsTabCount: unreadGroupRequests.length + unreadGroupInvitations.length,
        unreadBorrowingTabCount: unreadLendReturnRequests.length + unreadLends.length,
        unreadLendingTabCount: unreadLendReturnsIndicatedByBorrower.length + unreadConsiergeBorrows.length,
        unreadBorrowRequestsTabCount: unreadBorrowRequestsAccepted.length + unreadBorrowRequests.length,
        lendsTabHasUnreadNotifs: Boolean(unreadBorrowRequests.length) || Boolean(unreadBorrowRequestsAccepted.length) || Boolean(unreadLends.length) || Boolean(unreadLendReturnRequests.length),
        groupsTabHasUnreadNotifs: Boolean(unreadGroupRequests.length) || Boolean(unreadGroupInvitations.length),
        unreadFollowRequests,
        unreadMessagesCount,
        unreadGroupRequests,
        unreadGroupInvitations,
        unreadBorrowRequests,
        unreadLends,
        unreadLendReturnRequests,
        unreadLendReturnsIndicatedByBorrower,
        unreadConsiergeBorrows,
        unreadBorrowRequestsAccepted
    }
}

export function useDelayedValue(value, delay=500) {
    const [delayedValue, setDelayedValue] = useState(value);
    let valueTimeout = useRef(null);
    useEffect(() => {
        if(valueTimeout.current) {
            clearTimeout(valueTimeout.current);
            valueTimeout.current = null;
        }
        valueTimeout.current = setTimeout(
            () => {
                setDelayedValue(value)
            },
            delay
        )
    }, [value]);
    return delayedValue;
}

export function useAppSettings() {
    const [locale, setLocale] = useState(i18n.locale.slice(0,2));
    useEffect(() => {
        i18n.locale = locale;          
    }, [locale]);
    return useMemo(
        () => ({
            t: (scope, options) => i18n.t(scope, {locale, ...options}),
            l: (scope, date) => i18n.l(scope, date),
            timeAgoInWords: (fromDate, toDate, options) => i18n.timeAgoInWords(fromDate, toDate, {locale, ...options}),
            locale,
            setLocale
        }),
        [locale]
    );
}

export function useBooksSearchContextValue() {
    const [searchPattern, setSearchPattern] = useState('');
    const [filters, _setFilters] = useState(deepCopy(BOOK_FILTER_DEFAULT));
    const [keywords, setKeywords] = useState([]);
    const [filterCounts, setFilterCounts] = useState(null);
    const resetFilters = () => _setFilters(deepCopy(BOOK_FILTER_DEFAULT));
    const resetSearch = () => {
        resetFilters();
        setSearchPattern('');
    }
    return useMemo(
        () => ({
            searchPattern,
            setSearchPattern,
            filters,
            setFilters: (update) => _setFilters({ ...filters, ...update }),
            resetFilters,
            resetSearch,
            keywords,
            setKeywords,
            filterCounts,
            setFilterCounts,
        }),
        [
            searchPattern,
            filters, 
            keywords, 
            filterCounts
        ]
    );
}

export function useInitialNumberToRender(itemHeight) {
    const [containerHeight, setContainerHeight] = useState(null);
    const [initialNumToRender, setInitialNumToRender] = useState(null);
    useEffect(() => {
        if(containerHeight) {
            setInitialNumToRender(Math.ceil(containerHeight/itemHeight));
        }
        setInitialNumToRender(1)
    }, [containerHeight]);
    const onLayoutContainer = ({ nativeEvent: { layout: { height }}}) => {
        if(containerHeight === null && height) setContainerHeight(height);
    }
    return [initialNumToRender, onLayoutContainer];
}

export function useSearch(selectFilteredBooks, SearchContext) {
    const { setKeywords, setFilterCounts, filters:searchFilters, searchPattern, resetSearch } = useContext(SearchContext);
    const pattern = useDelayedValue(searchPattern);
    const filters = useDelayedValue(searchFilters, 200);
    const selectParams = useMemo(() => ({ pattern, filters }), [pattern, filters])
    const [books, filteredBooks, booksMatchedPattern] = useSelector(state => selectFilteredBooks(state, selectParams));
    const lending = useSelector(selectLending);
    const keywords = useKeywordsFromBooks(books.map(({ id }) => id), 'alphabetically');
    const isFilterEmpty = bookFilterIsEmpty(filters);
    useEffect(
        () => setKeywords(keywords), 
        [keywords]
    );
    useEffect(
        () => setFilterCounts(getBookFilterCountDetail(filters, booksMatchedPattern, keywords.map(({ id }) => id), lending)),
        [pattern, filters, lending, keywords]
    );
    return {
        pattern, 
        filters, 
        filteredBooks, 
        hasSearch: !isFilterEmpty || pattern,
        hasBooks: !!books.length, 
        resetSearch
    }
}

export function useLibraryScrollHandler(ScrollContext) {
    const { setScrollY, setScrollYDirection } = useContext(ScrollContext);
    const scrollY = useSharedValue(0);
    const scrollYDirection = useSharedValue(0);
    const scrollHandler = useAnimatedScrollHandler({
        onScroll: (e) => {
            setScrollY(e.contentOffset.y);
            if (e.contentOffset.y > scrollY.value) {
                // Scrolling up
                scrollYDirection.value = 1;
                setScrollYDirection(1);
            } else if (e.contentOffset.y < scrollY.value) {
                // Scrolling down
                scrollYDirection.value = -1;
                setScrollYDirection(-1);
            } else {
                // Stationary
                scrollYDirection.value = 0;
                setScrollYDirection(0);
            }
            scrollY.value = e.contentOffset.y;
        }
    })
    return isNative() ? scrollHandler : undefined;
}

export function useBooksScrollContext() {
    const [scrollY, _setScrollY] = useState(0);
    const [scrollYDirection, _setScrollYDirection] = useState(0);
    const setScrollY = (newScrollY) => {
        'worklet';
        if((newScrollY > PILL_TABS_SCROLL_HIDE_THRESHOLD && scrollY <= PILL_TABS_SCROLL_HIDE_THRESHOLD) || 
           (newScrollY < PILL_TABS_SCROLL_HIDE_THRESHOLD && scrollY > PILL_TABS_SCROLL_HIDE_THRESHOLD)) {
            runOnJS(_setScrollY)(newScrollY);
        }
    }
    const setScrollYDirection = (newScrollYDirection) => {
        'worklet';
        if(scrollYDirection !== newScrollYDirection) {
            runOnJS(_setScrollYDirection)(newScrollYDirection);
        }
    }
    return useMemo(() => ({
        scrollY,
        scrollYDirection,
        setScrollY,
        setScrollYDirection
    }), [scrollY, scrollYDirection])
}   
export function useBooksTabBarAnimStyles(ScrollContext) {
    const { scrollY, scrollYDirection } = useContext(ScrollContext);
    const translateY = useSharedValue(0);
    return useAnimatedStyle(() => {
        if(scrollYDirection === 1 && scrollY > PILL_TABS_SCROLL_HIDE_THRESHOLD) {
            translateY.value = withTiming(-PILL_TABS_HEIGHT);
        } else if(scrollYDirection === -1) {
            translateY.value = withTiming(0);
        }
        return { transform: [{ translateY: translateY.value }]}
        
    })
}