import React, { useContext, useCallback } from 'react';
import { View } from 'react-native';
import { selectAccount } from '../selectors';
import { createMaterialTopTabNavigator, MaterialTopTabBar } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import PublicGroupScreen from '../screens/Groups/PublicGroupsScreen';
import MyGroupsScreen from '../screens/Groups/MyGroupsScreen';
import GroupRequestsScreen from '../screens/Groups/GroupRequestsScreen';
import MainHeader from './MainHeader';
import Button from '../components/Button';
import Badge from '../components/Badge';
import { getTabBarOptions, isLargeScreen, isWeb, isIOS } from '../utils';
import { useSelector } from 'react-redux';
import { TAB_HEIGHT } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';
import { useUnreadNotifs } from '../hooks';

const tBase = 'tabs.groups';

const GroupsStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();
const Tab = createMaterialTopTabNavigator();

export default function GroupsTabsStack() {
    const {t} = useContext(AppSettingsContext);
    const insets = useSafeAreaInsets()
    const paddingBottom = (() => {
        if(isLargeScreen()) return 0;
        return TAB_HEIGHT + insets.bottom;
    })()
    return (
        <GroupsStack.Navigator 
            screenOptions={{ 
                header: ({navigation}) => (
                    <MainHeader title={t(`${tBase}.header.title`)} navigation={navigation} />
                ),
                cardStyle: { flex: 1, paddingBottom }
            }}>
            <GroupsStack.Screen
                name="GroupsTabs"
                component={GroupsTabs} />
        </GroupsStack.Navigator>
    )
} 

function GroupsTabs({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const { unreadGroupsTabCount:count } = useUnreadNotifs();
    const account = useSelector(selectAccount);
    const hidePublicGroupsTab = account?.params?.hidePublicGroupsTab;
    const renderBadge = useCallback(() => {
        if(count) return <Badge style={{ position: 'relative', top: 15, marginRight: 5 }} count={count} /> 
        return null;   
    }, [count]);
    const renderTabBar = useCallback(props => customTabBar(props, navigation, t), [t]);
    return (
        <Tab.Navigator 
            tabBar={renderTabBar}
            screenOptions={getTabBarOptions()}>
            { !hidePublicGroupsTab &&
            <Tab.Screen 
                name="PublicGroups" 
                component={PublicGroupScreen}
                options={{
                    tabBarLabel: t(`${tBase}.tabs.publicGroups.title`)
                }} />
            }
            <Tab.Screen 
                name="MyGroups" 
                component={MyGroupsScreen}
                options={{
                    tabBarLabel: t(`${tBase}.tabs.myGroups.title`)
                }} />
            <Tab.Screen 
                name="GroupRequests"
                component={GroupRequestsScreen}
                options={{
                    tabBarBadge: renderBadge,
                    tabBarLabel: t(`${tBase}.tabs.groupRequests.title`)
                }} />
        </Tab.Navigator>
  );
}

function customTabBar(props, navigation, t) {
    const handleAddGroup = () => {
        if(isWeb()) {
            navigation.navigate('Modal', { screen: 'ChooseGroupProduct' })
        } else {
            navigation.navigate('Modal', { screen: 'AddGroup' })
        }
    }
    return (
        <View>
            <MaterialTopTabBar
                {...props}
                renderLabel={({ route }) => {
                    return (
                        <InterReg 
                            style={{ color: SECONDARY_COLOR, fontSize: 15 }}
                            text={ props.descriptors[route.key].options.title } />
                    )
                }}
            />
            { isLargeScreen() &&
            <Button 
                text={ t(`group.add`) } 
                size='xSmall'
                onPress={handleAddGroup} 
                buttonStyle={{ position: 'absolute', paddingHorizontal: 15, zIndex: 10, right: 30, top: 2 }} />
            }
        </View>
    )
}