import React, { useEffect, useState, memo, useContext, useMemo } from 'react';
import { View, Dimensions } from 'react-native';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LinkingConfiguration from './LinkingConfiguration'; 
import NotFoundScreen from '../screens/NotFoundScreen';
import WelcomeScreen from '../screens/WelcomeScreen';
import SignInScreen from '../screens/SignInScreen';
import SignUpScreen from '../screens/SignUpScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import ModalStack from '../navigation/Modal';
import DrawerNavigator from './DrawerNavigator';
import PublicDrawerNavigator from './PublicDrawerNavigator';
import { selectAccount } from '../selectors';
import { useHandleInitialLoadPath } from '../hooks';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getModalType, getActiveScreenFromNavState, formatNavigationEventForLogging, handleUnreadNotifsDone, shouldLogEvent } from '../utils';
import { navigationRef, isReadyRef } from './RootNavigation';
import { setWindowDimensions, setActiveBottomTab } from '../actions';
import { logEventApi } from '../actions/logEvent';
import { getUnreadNotifsApi } from '../actions/getUnreadNotifs';
import { MODAL_MAX_WIDTH, MODAL_MAX_HEIGHT } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

const MyTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: '#fff',
    },
};

const Stack = createStackNavigator();

export default function Navigation(props) {
    const dispatch = useDispatch();
    const { height, width } = useSelector(state => state.settings.window, shallowEqual); 
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const prevNavigationEvent = useSelector(state => state.settings.navigationEvent, shallowEqual);
    const [initalLoadPathUsed, setInitalLoadPathUsed] = useState(false);
    useHandleInitialLoadPath(navigationRef, setInitalLoadPathUsed);
    const updateDimensions = () => {
        const window = Dimensions.get('window');
        if(!height || !width || window.height != height || window.width != width) {
            dispatch(setWindowDimensions(window))
        }
    }
    const handleOnStateChange = (state) => {
        updateDimensions();
        let currentActiveBottomTab;
        if(state?.routeNames[state?.index] == 'Root') {
            let stateToCheck = state?.routes[state?.index].state;
            while(stateToCheck) {
                let activeRouteName = stateToCheck?.routeNames[stateToCheck?.index];
                let nextState = stateToCheck?.routes[stateToCheck?.index]?.state
                if(activeRouteName && activeRouteName.match(/Tab$/)) {
                    currentActiveBottomTab = activeRouteName;
                    stateToCheck = undefined;
                } else if(nextState) {
                    stateToCheck = nextState;
                } else {
                    stateToCheck = undefined;
                }
            }
        }
        dispatch(getUnreadNotifsApi({})).then(handleUnreadNotifsDone);
        const nextNavigationEvent = formatNavigationEventForLogging(getActiveScreenFromNavState(state));
        if(shouldLogEvent(initalLoadPathUsed, prevNavigationEvent, nextNavigationEvent)) {
            dispatch(logEventApi(nextNavigationEvent));
        } else if(initalLoadPathUsed) {
            setInitalLoadPathUsed(false);
        }
        if(currentActiveBottomTab && currentActiveBottomTab != activeBottomTab) {
            dispatch(setActiveBottomTab(currentActiveBottomTab));
        }
    }
    useEffect(() => {
        updateDimensions();
        return () => {
            isReadyRef.current = false
        };
    }, []);
    const onReady = () => {
        isReadyRef.current = true
    }
    const onLayout = () => {
        updateDimensions();
    }
    return (
        <NavigationContainer 
            ref={navigationRef}
            theme={MyTheme}
            onStateChange={handleOnStateChange}
            onReady={onReady} 
            linking={LinkingConfiguration}>
            <View style={{flex: 1}} onLayout={onLayout}>
                <RootNavigator hideSplashScreen={props.hideSplashScreen} />
            </View>
        </NavigationContainer>
    );
}

const commonScreens = {
    NotFound: NotFoundScreen,
};

const authScreens = {
    Auth: WelcomeStack
};

const publicScreens = {
    Public: PublicDrawerNavigator
}

const userScreens = {
    Root: DrawerNavigator,
};

const forWebModal = ({ current: { progress }}) => ({
    cardStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 0.5, 0.9, 1],
        outputRange: [0, 0.25, 0.7, 1]
      }),
      transform: [
        { 
          translateY: progress.interpolate({
            inputRange: [0, 0.7, 1],
            outputRange: [-100, -20, 0]
          }) 
        },
      ]
    },
    overlayStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.5],
        extrapolate: "clamp"
      })
    }
});

const modalCardInterpolator = (usingTransparentModal) => {
    if(usingTransparentModal) {
      return { cardStyleInterpolator: forWebModal }
    } 
    return {};   
  }

const modalCardStyle = (usingTransparentModal) => {
    if(usingTransparentModal) {
        return {
            maxWidth: MODAL_MAX_WIDTH,
            maxHeight: MODAL_MAX_HEIGHT,
            minHeight: MODAL_MAX_HEIGHT,
            width: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            alignSelf: "center",
            borderRadius: 5,
            overflow: "hidden",
            backgroundColor: 'transparent'
        }
    } 
    return {
        maxWidth: 600,
        width: "100%",
        alignSelf: "center",
        backgroundColor: "#fff"
    };
}

const AuthStack = createStackNavigator();

function WelcomeStack() {
    return (
        <AuthStack.Navigator screenOptions={{ headerShown: false }}>
            <AuthStack.Screen name="Welcome" component={WelcomeScreen} />
            <AuthStack.Screen name="SignIn" component={SignInScreen} />
            <AuthStack.Screen name="SignUp" component={SignUpScreen} />
            <AuthStack.Screen name="ResetPassword" component={ResetPasswordScreen} />
        </AuthStack.Navigator>
    );
}

const RootNavigator = memo(({ hideSplashScreen }) => {
    const width = Dimensions.get('window').width;
    const modalType = getModalType(width);
    const usingTransparentModal = modalType == 'transparentModal';
    const account = useSelector(selectAccount);
    const hasAccount = Boolean(account?.id);
    const {locale, setLocale} = useContext(AppSettingsContext);
    useEffect(() => { hideSplashScreen() }, []);
    useEffect(() => {
        if(account && account?.preferredLang && locale.slice(0,2) !== account?.preferredLang.slice(0,2)) {
            setLocale(account.preferredLang);
        }
    }, [account]);
    const screens = useMemo(() => ({
        ...(hasAccount ? userScreens : authScreens),
        ...(hasAccount ? {} : publicScreens),
        ...commonScreens,
    }), [hasAccount]);
    return (
        <Stack.Navigator 
            initialRouteName={ hasAccount ? 'Root' : 'Auth' }
            screenOptions={{
                headerShown: false,
            }}>
            {Object.entries(screens).map(([name, component]) => (
                <Stack.Screen key={name} name={name} component={component} />
            ))}
            <Stack.Screen 
                name="Modal" 
                component={ModalStack} 
                options={{
                    presentation: modalType,
                    animationEnabled: true,
                    cardOverlayEnabled: true,
                    cardStyle: modalCardStyle(usingTransparentModal),
                    ...modalCardInterpolator(usingTransparentModal)
                }}
            />
        </Stack.Navigator> 
    );
})