import React, { memo, useContext } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { MAIN_COLOR, SECONDARY_COLOR, GRAY_LINE_COLOR, SECONDARY_COLOR_LIGHT, SUCCESS_COLOR } from '../constants';
import { getOtherIdFromLend, getOtherIdFromBorrowRequest, navigateToUser, getTimeSinceNow } from '../utils';
import { Ionicons } from '@expo/vector-icons';
import { useUser } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';
import dayjs from 'dayjs';

import InterReg from './InterReg';
import MontSBold from './MontserratBold';


function BookLendStatus({ 
        lend,
        navigation, 
        borrowRequest,  
        context
    }) {
    const {t, locale, timeAgoInWords, l} = useContext(AppSettingsContext);
    const userId = lend ? getOtherIdFromLend(lend, context) : getOtherIdFromBorrowRequest(borrowRequest, context);
    const user = useUser(userId);
    function handleUserPress() {
        if(user) navigateToUser(navigation, { userId });
    }
    const renderStatus = ({nickname, created, returnedDatetime, returnByDatetime, requestedReturnDatetime, returnIndicatedByBorrowerDatetime, parts, type='other'}) => {
        const namePart = <MontSBold onPress={handleUserPress} style={ styles.name } text={ nickname } />;
        const datePart = <MontSBold style={ styles.date } text={ l('date.formats.short', created) } />;
        const tick = <Ionicons name="checkmark-circle" size={16} color={SUCCESS_COLOR}  />;
        const showReturned = returnedDatetime;
        const showReturnRequested = requestedReturnDatetime && !returnedDatetime;
        const showReturnIndicatedByUser = returnIndicatedByBorrowerDatetime && !returnedDatetime; 
        const showReturnBy = !showReturnRequested && returnByDatetime && !returnedDatetime;
        const dueToBeReturnedInDays = returnByDatetime ? dayjs(returnByDatetime).diff(dayjs(), 'day') : null;
        return (
           <View> 
               <Text numberOfLines={ 2 } style={{ lineHeight: 20 }}>
                    { type === 'accepted' && tick }
                    { type === 'accepted' && ' ' }
                    <InterReg style={{ fontSize: 14, color: '#666' }} text={ parts[0] } /> 
                    { ' ' }
                    { type === 'request' ? datePart : namePart }
                    { ' ' }
                    <InterReg style={{ fontSize: 14, color: '#666' }} text={ parts[1] } />
                    { ' ' }
                    { type === 'request' ? namePart : datePart }
                </Text>
                { showReturned && 
                    <Text style={{ paddingTop: 3 }}>
                        <InterReg style={{ fontSize: 14, color: '#666' }} text={t('book.returned')} />
                        { ' ' }
                        <MontSBold style={ styles.date } text={ l('date.formats.short', returnedDatetime) } /> 
                    </Text>
                }
                { showReturnRequested && 
                    renderInfo({ 
                        iconKey: type == 'borrowing' ? 'warning' : 'info', 
                        text: t('book.returnRequested'), 
                        datetime: requestedReturnDatetime
                    })
                }
                { showReturnIndicatedByUser && 
                    renderInfo({ 
                        iconKey:'info', 
                        text: t('book.returnIndicatedByBorrower', { nickname: type == 'borrowing' ? t('general.you') : nickname }), 
                        datetime: returnIndicatedByBorrowerDatetime
                    })
                }
                { showReturnBy && 
                    renderInfo({ 
                        iconKey: dueToBeReturnedInDays >= 0 ? 'info' : 'warning', 
                        text: t(dueToBeReturnedInDays >= 0 ? 'book.returnBy' : 'book.returnByOverDue', { count: Math.abs(dueToBeReturnedInDays)  })
                    })
                }
           </View>
       )
    }
    const renderInfo = ({ iconKey, text, datetime }) => {
        const icons = {
            info: { name: 'information-circle', color: SECONDARY_COLOR_LIGHT, size: 17, style: {position: 'relative', bottom: -1} },
            warning: { name: 'warning', color: 'orange', size: 16 }
        };
        const iconProps = icons[iconKey];
        return (
            <Text style={{ paddingTop: 3 }}>
                { iconProps && <Ionicons name={iconProps.name} size={iconProps.size} color={iconProps.color}  /> }
                { iconProps && ' ' }
                <InterReg style={{ fontSize: 14 }} text={text} />
                { datetime && <>
                    { ' ' }
                    <InterReg style={{ fontSize: 14, color: '#666' }} text={ getTimeSinceNow(datetime, locale, timeAgoInWords)} />
                </>}
            </Text>
        )
    }
    const acceptedBorrowRequestByMe = context == 'borrowRequestByMe' && borrowRequest?.accepted;
    const borrowRequestByMe = context == 'borrowRequestByMe' && !borrowRequest?.accepted;
    const acceptedBorrowRequestToMe = context == 'borrowRequestToMe' && borrowRequest?.accepted;
    const borrowRequestToMe = context == 'borrowRequestToMe' && !borrowRequest?.accepted;
    const nickname = user?.nickname ?? t('user.notFound');
	return (
		<View style={{ borderTopWidth: StyleSheet.hairlineWidth, borderTopColor: GRAY_LINE_COLOR, paddingTop: 8, marginTop: 8 }}>
            { context == 'borrowing' ?
                renderStatus({ ...lend, nickname, parts: [t('book.borrowingFrom'), t('book.onThe')], type: context })
                : null 
            }
            { context == 'lending' ?
                renderStatus({ ...lend, nickname, parts : [t('book.lendingTo'), t('book.onThe')], type: context })
                : null 
            }
            { context == 'borrowed' ?
                renderStatus({ ...lend, nickname, parts: [t('book.borrowedFrom'), t('book.onThe'), t('book.returned')]})
                : null 
            }
            { context == 'lent' ?
                renderStatus({ ...lend, nickname, parts : [t('book.lentTo'), t('book.onThe'), t('book.returned')]})
                : null 
            }
            { borrowRequestByMe ?
                renderStatus({ ...borrowRequest, nickname, parts : [t('book.requestedOnThe'), t('book.requestedFrom')], type: 'request'})
                : null 
            }
            { borrowRequestToMe ?
                renderStatus({ ...borrowRequest, nickname, parts : [t('book.requestedOnThe'), t('book.requestedBy')], type: 'request'})
                : null 
            }
            { acceptedBorrowRequestByMe ?
                renderStatus({ ...borrowRequest, nickname, parts : [t('book.requestAcceptedBy'), t('book.onThe')], type: 'accepted'})
                : null
            }
            { acceptedBorrowRequestToMe ?
                renderStatus({ ...borrowRequest, nickname, parts : [t('book.youAcceptedRequestBy'), t('book.onThe')], type: 'accepted'})
                : null
            }
        </View>
	)
}

export default memo(BookLendStatus);

const styles = StyleSheet.create({
    name: {
        color: SECONDARY_COLOR,
        fontSize: 14
    },
    date: {
        color: MAIN_COLOR,
        fontSize: 14
    }
})