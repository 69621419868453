import React, { memo, useContext, useMemo } from 'react';
import Img from './Img';
import { getBookCoverURI, getColorHsp } from '../utils';
import { View, ActivityIndicator } from 'react-native';
import { BOOK_COVER_RATIO, MAIN_COLOR, GRAY_TEXT_PALE } from '../constants';
import { SimpleLineIcons } from '@expo/vector-icons';
import { getAverageColor } from '../utils/blurhash';
import InterReg from './InterReg';
import AppSettingsContext from '../context/AppSettingsContext';

const DEFAULT_COVER_WIDTH = 140;

const Cover = memo((props) => {
    const {t} = useContext(AppSettingsContext);
    const {
        cover,
        coverHash,
        blurHash, 
        style={},
        height, 
        width, 
        placeholderCoverText=t('general.noCover'), 
        useCachedImg=true,
        resizeMode='cover',
        loading=false
    } = props;
    const uri = getBookCoverURI(cover, coverHash);
    const coverWidth = width ? width : DEFAULT_COVER_WIDTH;
    const coverHeight = height ? height : coverWidth * BOOK_COVER_RATIO;
    const iconSize = Math.floor(DEFAULT_COVER_WIDTH / 4.6);
    const backgroundColor = blurHash ? `rgb(${getAverageColor(blurHash).join(',')})` : '#ddd';
    const hsp = getColorHsp(backgroundColor); 
    const borderWidth = hsp > 215 && blurHash ? 1 : 0;
    const borderColor = hsp > 215 && blurHash ? '#f0f0f0' : 'transparent';
    if(uri) { 
        return (
            <Img 
                uri={ uri }
                resizeMode={resizeMode}
                useCachedImg={useCachedImg} 
                style={[
                    { borderWidth, borderColor, borderRadius: 5, height: coverHeight, width: coverWidth, backgroundColor }, 
                    style
                ]} />
        )
    }
    return (
        <View style={[{ alignItems: 'center', justifyContent: 'center', borderRadius: 5, height: coverHeight, width: coverWidth,  backgroundColor: '#ddd' }, style]}>
            { loading ?
                <ActivityIndicator color={ MAIN_COLOR } size='large' />
            :
                <>
                    <SimpleLineIcons name="book-open" size={iconSize} color={GRAY_TEXT_PALE} />
                    { width > 100 &&
                    <InterReg text={ placeholderCoverText } style={{ fontSize: 13, marginTop: 7, color: '#83888E' }} />
                    }
                </>
            }
        </View>
    )
})

export default Cover;