import React, { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import { SECONDARY_COLOR } from '../constants';
import AppHeader, { HEADER_HEIGHT } from './AppHeader';
import { useSelector } from 'react-redux';
import { selectFollowRequestsToMe, selectRemainingProfileElementsToFill } from '../selectors';
import Badge from '../components/Badge';
import { isLargeScreen } from '../utils';
import { useUnreadNotifs } from '../hooks';

export default function MainHeader({ title, navigation }) {
    const remainingProfileElementsToFill = useSelector(selectRemainingProfileElementsToFill);
    const followRequests = useSelector(selectFollowRequestsToMe);
    const { unreadMessagesCount } = useUnreadNotifs();
    const badgeCount = unreadMessagesCount + remainingProfileElementsToFill + followRequests.length;
    const renderHeaderLeft = useCallback(() => {
        if(isLargeScreen()) return null;
        const handleDrawerPress = () => navigation.toggleDrawer();
        return (
            <Pressable style={styles.buttons} onPress={handleDrawerPress}>
                <Entypo size={30} name="menu" style={{ marginTop: -3 }} color={SECONDARY_COLOR} />
                { !!badgeCount && <Badge count={badgeCount} left={22} /> }
            </Pressable>
        )
    }, [badgeCount]);
    const handleSearchPress = () => navigation.navigate('Modal', { screen: 'Search' });
    const renderHeaderRight = useCallback(() => (
        <Pressable style={styles.buttons} onPress={handleSearchPress}>
            <Ionicons size={25} name="search" style={{ marginTop: -2 }} color={SECONDARY_COLOR} />
        </Pressable>
    ), [])
    return (
        <AppHeader 
            title={title}
            titleStyle={{ textTransform: 'uppercase', fontSize: 18 }}
            headerLeft={renderHeaderLeft}
            headerRight={renderHeaderRight}
        />
    )
}

const styles = StyleSheet.create({
    buttons: {
        flex: 1,
        height: HEADER_HEIGHT,
        width: HEADER_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center'
    }
})