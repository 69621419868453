import { GET_ACCOUNT_PRODUCTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getAccountProductsInProgress = createAction('GET_ACCOUNT_PRODUCTS_IN_PROGRESS');
export const getAccountProductsSuccess = createAction('GET_ACCOUNT_PRODUCTS_SUCCESS');
export const getAccountProductsFailure = createAction('GET_ACCOUNT_PRODUCTS_FAILURE');

export function getAccountProductsApi() {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getAccountProductsInProgress, getAccountProductsSuccess, getAccountProductsFailure],
            name: 'getAccountProducts',
            dispatch,
            getState,
            showLoading: false,
            checkCaching: false
        })
    }
}