export default {
    alerts: {
        acceptBorrowRequest: {
            title: "Accepter la demande d'emprunt",
            subTitle: "Une fois accepté il faudrait confirmer l'échange du livre."
        },
        acceptGroupRequest: {
            title: "Accepter la demande de rejoindre votre groupe",
            subTitle: "En acceptant '%{nickname}' deviendra membre de '%{name}'."
        },
        acceptRequestFollowUser: {
            title: "Accepter la demande de vous suivre",
            subTitle: "En acceptant %{nickname} pourra voir vos étagères et listes visible pour vos abonnés."
        },
        actions: {
            accept: "Accepter",
            add: "Ajouter",
            addManually: "Ajout manuel",
            cancel: "Annuler",
            cancelRequest: "Annuler la demande",
            delete: "Supprimer",
            exchanged: "Échangé",
            fillProfile: "Remplir son profil",
            leave: "Quitter",
            modify: "Modifier",
            refuse: "Refuser",
            remove: "Retirer",
            request: "Envoyer",
            return: "Rendu",
            sendRequest: "Envoyer"
        },
        addExistingBook: {
            title: "Ce livre est déjà présent dans votre bibliothèque",
            subTitle: "Souhaitez-vous toujours l'ajouter ?"
        },
        allowAccessToContacts: {
            title: "Accès refusé à vos contacts",
            subTitle: "Merci d’autoriser MyBlio à accèder à vos contacts."
        },
        askForFeedback: {
            title: "Vous appréciez cette application ?",
            subTitle: "Nous aimerions savoir si vous aimez l'application. Vos commentaires nous aideront à faire la différence !",
            ok: "😕 Pas mal",
            great: "😍 J’adore"
        },
        bookBorrowedFrom: {
            title: "Ce livre a-t-il été emprunté à %{username}?",
            subTitle: "Une fois que l'échange sera confirmé, il sera visible dans l'onglet emprunts."
        },
        bookLentTo: {
            title: "Ce livre a-t-il été prêté à %{username}?",
            subTitle: "Une fois que l'échange sera confirmé il sera visible dans l'onglet prêts."
        },
        cancelBorrowRequest: {
            title: "Annuler la demande d'emprunt ?",
            subTitle: ""
        },
        deleteAccount: {
            title: "Supprimer votre compte ?",
            subTitle: "Attention, cette action est définitive."
        },
        deleteBook: {
            title: "Supprimer ce livre ?",
            subTitle: "Attention, cette action est définitive."
        },
        deleteBookReview: {
            title: "Supprimer votre évaluation de ce livre ?",
            subTitle: "Attention, cette action est définitive."
        },
        deleteGroup: {
            title: "Supprimer ce groupe ?",
            subTitle: "Attention, '%{name}' sera définitement supprimé."
        },
        deleteGroupCollection: {
            title: "Supprimer cette collection ?",
            subTitle: "Attention, '%{name}' sera définitement supprimé."
        },
        deleteList: {
            title: "Supprimer cette liste ?",
            subTitle: "Attention, cette action est définitive."
        },
        deleteShelf: {
            title: "Supprimer cette étagère ?",
            subTitle: "Attention, les livres qui en font partie seront également supprimés."
        },
        deleteUserKeyword: {
            title: "Supprimer ce mot clé ?",
            subTitle: "Attention, cette action est définitive."
        },
        emailSmtpError: {
            title: "⚠️ Erreur d'adresse e-mail",
            subTitle: "Merci de vérifier l'adresse suivante : '%{email}'."
        },
        joinGroup: {
            title: "Demander à rejoindre ce groupe ?",
            subTitle: "L'administrateur·trice de '%{name}' sera notifié·e de votre demande."
        },
        leaveGroup: {
            title: "Quitter ce groupe ?",
            subTitle: "Vous ne serez plus membre de '%{name}' et vos livres partagés seront retirés."
        },
        noResultFromScannedIsbn: {
            title: "Oups! Aucun livre trouvé",
            subTitle: "Vous pouvez toujours l'ajouter manuellement."
        },
        profileMustBeFilledToRequestBook: {
            title: "Plus qu'une étape pour emprunter ce livre",
            subTitle: "Un profil bien rempli et c'est parti !"
        },
        promptLoginOrSignup: {
            title: "Compte MyBlio nécessaire",
            login: "Se connecter",
            or: "ou",
            signup: "Créer un compte",
            subTitle: "Créez un compte ou connectez-vous pour continuer."
        },
        refuseBorrowRequest: {
            title: "Refuser la demande d'emprunt ?",
            subTitle: ""
        },
        refuseGroupRequest: {
            title: "Refuser la demande de rejoindre votre groupe ?",
            subTitle: "%{nickname} souhaite rejoindre %{name}."
        },
        refuseRequestFollowUser: {
            title: "Refuser la demande de vous suivre ?",
            subTitle: "%{nickname} ne sera pas avertie."
        },
        requestReturnBook: {
            title: "Demander le retour de ce livre ?",
            subTitle: "%{nickname} sera notifié·e de votre souhait de vouloir récupérer « %{bookTitle} »."
        },
        removeFromAllLists: {
            title: "Retirer de toute vos listes ?",
            subTitle: "Le livre '%{title}' sera donc supprimé de votre bibliothèque."
        },
        removeFromList: {
            title: "Retirer ce livre de cette liste ?",
            subTitle: ""
        },
        removeMemberFromGroup: {
            title: "Retirer ce membre de votre groupe ?",
            subTitle: "%{nickname} sera retiré ainsi que ses livres partagés dans le groupe."
        },
        requestFollowUser: {
            title: "Envoyer la demande d'abonnement ?",
            subTitle: "Le profil de %{nickname} est privé. Vous pourrez le consulter une fois que la demande sera accpetée."
        },
        returnBook: {
            title: "Indiquer que ce livre a été rendu ?",
            subTitle: ""
        },
        returnBookNyBorrower: {
            title: "Indiquer que vous avez rendu ce livre ?",
            subTitle: "Le propriétaire du livre sera notifié et pourra valider le retour."
        },
        sendBorrowRequest: {
            title: "Envoyer une demande pour emprunter",
            from: "à"
        }
    },
    book: {
        add: "Ajouter un livre",
        borrow: "Emprunter",
        borrowedFrom: "Enprunté à",
        borrowingFrom: "Emprunté à",
        lending: "Prêté",
        lendingTo: "Prêté à",
        lentTo: "Prété à",
        notLending: "Pas prêté",
        notPossessed: "Je n'ai pas",
        onThe: "le",
        possessed: "J'ai",
        requestAcceptedBy: "Demande acceptée par",
        requestedBy: "par",
        requestedFrom: "à",
        requestedOnThe: "Demandé le",
        returned: "Rendu le",
        returnRequested: "Retour demandé",
        returnIndicatedByBorrower: "Retour indiqué par %{nickname}",
        returnBy: {
            one: "Livre à rendre demain",
            other: "Livre à rendre dans %{count} jours",
            zero: "Livre à rendre aujourd'hui"
        },
        returnByOverDue: {
            one: "Livre aurait dû être rendu hier",
            other: "Livre aurait dû être rendu il y a %{count} jours"
        },
        sort: {
            authorAZ: "Auteur A-Z",
            authorZA: "Auteur Z-A",
            newest: "Ajout récent en premier",
            oldest: "Ajout récent en dernier",
            titleAZ: "Titre A-Z",
            titleZA: "Titre Z-A",
            collectionAZ: "Collection A-Z",
            collectionZA: "Collection Z-A",
            publishedNewest: "Date de publication (plus récent en premier)",
            publishedOldest: "Date de publication (plus récente en dernier)"
        },
        status: {
            own: "J'ai",
            read: "J'ai lu",
            reading: "Je lis",
            toRead: "A lire",
            unread: "Pas lu"
        },
        viewAll: "Voir tous les livres",
        youAcceptedRequestBy: "Vous avez accepté la demande de",
        filters: {
            title: "Filtres",
            readingStatus: "État de lecture",
            ownership: "Possession",
            lending: "État de prêt",
            keywords: "Mots clés",
            clearAll: "Reinitialiser"
        }
    },
    changelog: {
        features: {
            easierAccessToListOptions: "Accès direct aux options d’une liste sans avoir à consulter ses détails.",
            easierAccessToShelfOptions: "Accès direct aux options d’une étagère sans avoir à consulter ses détails.",
            sortBooksByPublishedDate: "Trier les livres par leur date de publication.",
            searchBookCodeField: "Trouvez un livre dans votre bibliothèque en utilisant la valeur renseignée dans le champ cote.",
            universalLinksForSomeUlrs: "L’application s’ouvre automatiquement lorsque vous cliquez sur des liens web vers des livres, des étagères, des listes, des groupes ou des profils d’utilisateurs.",
            newAllBooksTab: "Nouvel onglet « Tous », qui permet de voir l'ensemble des livres dans sa bibliothèque.",
            searchEitherAllBooksShelfsOrLists: "Possibilité de rechercher parmi les livres des onglets « Tous »,  « Étagères » et « Listes ».",
            filterEitherAllBooksShelfsOrLists: "Possibilité de filtrer les livres des onglets « Tous »,  « Étagères » et « Listes ».",
            betterAppBadgeManagment: "Meilleure gestion du badge de notifications sur l'icône de l’application.",
            shareLibraryScreenForNative: "L'écran de partage affiche désormais le lien vers votre bibliothèque, avec une option pour la partager facilement.",
            premiumAccountPersonalisedKeywords: "[Premium] Pouvoir créer des mots-clés personnalisés pour mieux catagoriser ses livres.",
            premiumLendDuration: "[Premium] Pouvoir définir la durée des vos prêts. L'application s'occupe d'envoyer des rappels automatiques à vos emprunteurs, y compris en cas de retard.",
            premiumLendsHistory: "[Premium] Gardez une trace de tous vos échanges de livres passés avec un historique complet de vos prêts et emprunts terminés.",
            premiumCodeField: "[Premium] Nouveau champ « cote » peut être renseigné sur la fiche d'un livre."
        }
    },
    date: {
        abbr_day_names: [
            "dim.",
            "lun.",
            "mar.",
            "mer.",
            "jeu.",
            "ven.",
            "sam."
        ],
        abbr_month_names: [
            null,
            "janv.",
            "févr.",
            "mars",
            "avr.",
            "mai",
            "juin",
            "juil.",
            "août",
            "sept.",
            "oct.",
            "nov.",
            "déc."
        ],
        day_names: [
            "dimanche",
            "lundi",
            "mardi",
            "mercredi",
            "jeudi",
            "vendredi",
            "samedi"
        ],
        formats: {
            long: "%-d %B %Y",
            messageLong: "%a %-d %b à %k:%M",
            messageNoYear: "%d %b",
            messageTime: "%k:%M",
            messageWithYear: "%d %b %Y",
            messageYesterday: "Hier à %k:%M",
            short: "%d/%m/%y",
            yesterday: "Hier"
        },
        month_names: [
            null,
            "janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre"
        ],
        since: {
            daysAgo: {
                one: "hier",
                other: "il y a %{count} jours",
                zero: "il y a %{count} jours"
            },
            hoursAgo: {
                one: "il y a 1 heure",
                other: "il y a %{count} heures",
                zero: "il y a %{count} heures"
            },
            minutesAgo: {
                one: "il y a 1 minute",
                other: "il y a %{count} minutes",
                zero: "il y a %{count} minutes"
            },
            momentsAgo: "à l'instant"
        },
        units: {
            days: {
                one: "jour",
                other: "jours",
                zero: "jours"
            },
            hours: {
                one: "heure",
                other: "heures",
                zero: "heures"
            },
            minutes: {
                one: "minute",
                other: "minutes",
                zero: "minutes"
            }
        }
    },
    datetime: {
        distance_in_words: {
            about_x_hours: {
                one: "environ une heure",
                other: "environ %{count} heures"
            },
            about_x_months: {
                one: "environ un mois",
                other: "environ %{count} mois"
            },
            about_x_years: {
                one: "environ un an",
                other: "environ %{count} ans"
            },
            almost_x_years: {
                one: "presqu'un an",
                other: "presque %{count} ans"
            },
            half_a_minute: "une demi-minute",
            less_than_x_minutes: {
                one: "moins d'une minute",
                other: "moins de %{count} minutes",
                zero: "moins d'une minute"
            },
            less_than_x_seconds: {
                one: "moins d'une seconde",
                other: "moins de %{count} secondes",
                zero: "moins d'une seconde"
            },
            over_x_years: {
                one: "plus d'un an",
                other: "plus de %{count} ans"
            },
            x_days: {
                one: "1 jour",
                other: "%{count} jours"
            },
            x_minutes: {
                one: "1 minute",
                other: "%{count} minutes"
            },
            x_months: {
                one: "1 mois",
                other: "%{count} mois"
            },
            x_seconds: {
                one: "1 seconde",
                other: "%{count} secondes"
            },
            x_years: {
                one: "1 an",
                other: "%{count} ans"
            }
        }
    },
    forms: {
        addBookSearch: {
            errors: {
                queryMin3Chars: "Il faut au moins 3 caractères",
                queryRequired: "Saisissez votre recherche"
            },
            labels: {
                query: ""
            },
            placeholders: {
                query: "Titre, auteur, ISBN…"
            }
        },
        addBookToLists: {
            labels: {
                listIds: "Choisir une liste(s)"
            },
            placeholders: {
                listIds: "Choisir une ou plusieurs liste"
            }
        },
        askForFeedback: {
            placeholders: {
                comments: "Y a-t-il autre chose que vous aimeriez nous dire sur votre expérience d'utilisation de MyBlio ?"
            },
            labels: {
                overallAppScore: "À quel point aimez-vous l'application dans son ensemble ?",
                usabilityScore: "À quel point êtes-vous satisfait de l’utilisabilité de l'application ?",
                loadingScore: "À quel point êtes-vous satisfait du temps de chargement de l'application ?",
                featuresScore: " À quel point êtes-vous satisfait des fonctionnalités de l'application ?",
                comments: "Commentaires"
            },
            errors: {
                answerRequired: "Réponse nécessaire",
                commentTooLong: "Le commentaire doit être de %{limit} caractères maximum", 
            }
        },
        book: {
            chooseCover: "Choisir la couverture",
            errors: {
                isbnInvalid: "l'ISBN nest pas valide",
                listIdRequired: "Choisir au moins une liste",
                shelfRequired: "Choisir une étagère",
                titleRequired: "Le titre est nécessaire",
                yearInFuture: "L'année ne peut pas être dans le futur !",
                yearMustBe4Digits: "L'année doit contenir 4 chiffres. (ex. 2010)",
                yearOnlyDigits: "L'année doit être des chiffres. (ex. 1234…)",
                monthMaxValue: "Le mois peut pas être supérieur à 12",
                monthMinValue: "Le mois ne peut être 0",
                dayMaxValue: "Le jour peut pas être supérieur à 31",
                dayMinValue: "Le jour ne peut être 0",
                pageCountTooBig: "Le nombre est trop gros",
                codeTooLong: "La cote doit être de %{limit} caractères maximum"

            },
            labels: {
                title: "Titre",
                author: "Auteur.ice(s)",
                description: "Résumé",
                isbn: "ISBN",
                lang: "Langue",
                listIds: "Liste(s)",
                note: "Notes",
                pageCount: "Nombre de pages",
                possessed: "Je possède ce livre",
                publisher: "Éditeur",
                collection: "Collection",
                collectionNbr: "Numéro de la collection",
                shelfId: "Étagère",
                subTitle: "Sous-titre",
                year: "Année de publication",
                month: "Mois de publication",
                day: "Jour de publication",
                keywordIds: "Mots clés",
                code: "Cote"
            },
            modifyCover: "Modifier",
            placeholders: {
                title: "Titre du livre",
                author: "Auteur #1, auteur #2",
                description: "De quoi parle ce livre ?",
                isbn: "ex. 978000000000",
                listIds: "Choisir une liste",
                note: "Vos propres commentaire à propos de ce livre.",
                pageCount: "",
                publisher: "ex. J'ai lu",
                collection: "ex. Que sais-je ?",
                collectionNbr: "ex. 5",
                shelfId: "Choisir une étagère",
                subTitle: "",
                year: "ex. 2010",
                month: "ex. 10",
                day: "ex. 5",
                keywordIds: "ex. Roman, Bande dessinée…",
                code: "Cote du livre"
            },
            sections: {
                bookDetails: "Détail du livre",
                bookPlacement: "Où ranger le livre ?"
            }
        },
        bookProvider: {
            errors: {
                listIdRequired: "Choisir au moins une liste",
                shelfRequired: "Choisir une étagère"
            },
            labels: {
                listIds: "Choisir une liste(s)",
                shelfId: "Choisir une étagère"
            },
            placeholders: {
                listIds: "Choisir une ou plusieurs liste",
                shelfId: "Choisir une étagère"
            }
        },
        bookReview: {
            errors: {
                bodyRequired: "L'avis est nécessaire",
                recommendedRequired: "Recommandation nécessaire",
                reviewTooLong: "L'avis doit être de %{limit} caractères maximum",
                tooFewTags: "Choisir au moins un mot clé",
                tooManyTags: "Vous pouvez choisir jusqu'à %{limit} mots clés"
            },
            labels: {
                body: "Votre avis",
                recommended: "Recommanderez-vous ce livre ?",
                tags: "Choisir des mots clés"
            },
            placeholders: {
                body: "Vous en avez pensé quoi de ce livre ?"
            },
            recommend: {
                no: "Non",
                yes: "Oui"
            }
        },
        changePassword: {
            errors: {
                confirmPasswordRequired: "Vous devez ressaisir votre nouveau mot de passe",
                currentPpasswordRequired: "Votre mot de passe actuel est nécessaire",
                newPasswordRequired: "Nouveau mot de passe nécessaire",
                passwordNotStrong: "Le mot de passe doit contenir au moins 6 caractères et 1 chiffre",
                passwordsNotTheSame: "Les mots de passe ne sont pas identiques"
            },
            labels: {
                confirmPassword: "Confirmer le mot de passe",
                currentPassword: "Mot de passe actuel",
                newPassword: "Nouveau mot de passe"
            },
            placeholders: {
                confirmPassword: "Saisissez-le à nouveau",
                currentPassword: "Le mot de passe que vous utilisez en ce moment",
                newPassword: "Votre nouveau mot de passe"
            },
            submit: "Modifier"
        },
        filterGroup: {
            labels: {
                search: ""
            },
            placeholders: {
                search: "Chercher dans le groupe"
            },
            keywords: {
                all: "Tous"
            }
        },
        group: {
            choosePicture: "Choisir une image",
            descriptions: {
                public: "Si vous désactivez cette option votre groupe sera privé et seul les membres ou ceux avec qui vous avez partagé son lien pourront le voir."
            },
            errors: {
                descriptionTooLong: "La description doit être de %{limit} caractères maximum",
                invalidSlug: "Le lien n'est pas valide. Il doit avoir ce format : nom-de-mon-group",
                invalidUrl: "Ne semble pas être valide",
                locationRequired: "Lieu nécessaire",
                locationTooLong: "Le lieu doit être de %{limit} caractères maximum",
                nameRequired: "Le nom est nécessaire",
                nameTooLong: "Le nom doit être de %{limit} caractères maximum",
                slugRequired: "Le lien court du groupe est nécessaire",
                slugTooLong: "Le lien court du groupe doit être de %{limit} caractères maximum",
                usageRequired: "Le type d'usage est nécessaire"
            },
            labels: {
                description: "Description",
                location: "Lieu",
                name: "Nom",
                public: "Groupe public",
                slug: "Lien court",
                usage: "Type d'usage",
                websiteUrl: "Site web"
            },
            modifyPicture: "Modifier l'image",
            noPicture: "Aucune \rimage \rselectionnée",
            placeholders: {
                description: "Dire quelque chose sur le but de ce groupe.",
                location: "Ville, région, pays…",
                name: "",
                slug: "nom-de-mon-groupe",
                usage: "Quel sera le type d'usage du groupe ?",
                websiteUrl: "Un lien externe en rapport au groupe"
            }
        },
        groupCollection: {
            errors: {
                descriptionTooLong: "La description doit être de %{limit} caractères maximum",
                nameRequired: "Le nom est nécessaire",
                nameTooLong: "Le nom doit être de %{limit} caractères maximum",
                sortRequired: "Le tri est nécessaire"
            },
            labels: {
                description: "Description",
                name: "Nom",
                sort: "Tri des livres"
            },
            placeholders: {
                description: "",
                name: "Nom de la collection",
                sort: "Choisir le tri"
            }
        },
        groupInvitationByCsv: {
            errors: {
                csvRequired: "Un fichier .csv est nécessaire"
            },
            labels: {
                emails: "Utiliser un fichier .csv avec les emails a inviter dans la première colonne"
            },
            buttons: {
                chooseFile: "Choisir fichier CSV"
            }
        },
        groupInvitationByEmail: {
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire"
            },
            labels: {
                email: "E-mail"
            },
            placeholders: {
                email: "L'e-mail de la personne à inviter"
            },
            submit: "Envoyer l'invitation"
        },
        inviteUrl: {
            descriptions: {
                url: "Il suffit de le partager avec les personnes à inviter."
            },
            labels: {
                url: "Par lien"
            }
        },
        inviteUserByEmail: {
            descriptions: {
                emailsToInvite: "Une invitation sera envoyée automatiquement par e-mail."
            },
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire"
            },
            labels: {
                emailsToInvite: "Par e-mail"
            },
            placeholders: {
                emailsToInvite: "L'e-mail de la personne à inviter"
            },
            submit: "Envoyer"
        },
        lend: {
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire"
            },
            labels: {
                email: "E-mail"
            },
            placeholders: {
                email: "L'e-mail de la personne à qui est prêté ce livre"
            },
            submit: "Signaler le prêt"
        },
        list: {
            errors: {
                descriptionTooLong: "La description doit être de %{limit} caractères maximum",
                nameRequired: "Le nom est nécessaire",
                nameTooLong: "Le nom doit être de %{limit} caractères maximum",
                sortRequired: "Le tri est nécessaire",
                visibility: "Le niveau de visibilité est nécessaire"
            },
            labels: {
                description: "Description",
                name: "Nom",
                sort: "Tri des livres",
                visibility: "Visibilité"
            },
            placeholders: {
                description: "",
                name: "Nom de la liste",
                sort: "Choisir le tri",
                visibility: "Choisir la visibilité"
            }
        },
        passwordReset: {
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire"
            },
            labels: {
                email: "E-mail"
            },
            navigate: {
                signin: "Se connecter"
            },
            placeholders: {
                email: "exemple@gmail.fr"
            },
            submit: "Réinitialiser mot de passe"
        },
        search: {
            errors: {
                patternMin3Chars: "Il faut au moins 3 caractères",
                patternRequired: "Saisissez votre recherche"
            },
            labels: {
                pattern: ""
            },
            placeholders: {
                pattern: "Chercher un livre, utilisateur ou groupe"
            }
        },
        searchBooks: { 
            books: {
                placeholders: {
                    pattern: "Chercher parmi vos livres"
                }
            },
            shelves: {
                placeholders: {
                    pattern: "Chercher parmi vos étagères"
                }
            },
            lists: {
                placeholders: {
                    pattern: "Chercher parmi vos listes"
                }
            },
            cancel: "Annuler"
        },
        settings: {
            labels: {
                preferredLang: "Langue souhaitée",
                lendDurationInDays: "Durée de vos prêts",
                sortBooks: "Tri des livres"
            },
            descriptions: {
                sortBooks: "Permet de changer le tri des livres dans votre bibliothèque."
            }
        },
        shelf: {
            errors: {
                nameRequired: "Le nom est nécessaire",
                nameTooLong: "Le nom doit être de %{limit} caractères maximum",
                sortRequired: "Le tri est nécessaire",
                visibility: "Le niveau de visibilité est nécessaire",
            },
            labels: {
                name: "Nom",
                sort: "Tri des livres",
                visibility: "Visibilité"
            },
            placeholders: {
                name: "Nom de l'étagère",
                sort: "Choisir le tri",
                visibility: "Choisir la visiblité"
            }
        },
        shelfShareBooksGroup: {
            labels: {
                onSiteLocationForGroup: "Localisation des livres",
                shouldAutoAdd: "Ajout automatique",
                availableOnSite: "Livres disponibles sur place",
                availableSelfService: "Livres disponibles en libre-service"
            },
            descriptions: {
                shouldAutoAdd: "Les livres ajoutés à cette étagère seront automatiquement partagés dans le groupe « %{groupName} ».",
                availableOnSite: "Les livres de cette étagère, partagés dans le groupe « %{groupName} », sont disponible sur place.",
                availableSelfService: "Les livres de cette étagère, partagés dans le groupe « %{groupName} », peuvent être empruntés de façon autonome par les membres."
            },
            placeholders: {
                onSiteLocationForGroup: "Renseignez le lieu où les livres sont disponibles"
            },
            errors: {
                locationTooLong: "La location doit être de %{limit} caractères maximum",
            },
        },
        signin: {
            dontHaveAccount: "Je n'ai pas de compte ! ",
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire",
                passwordRequired: "Le mot de passe est nécessaire"
            },
            labels: {
                email: "E-mail",
                password: "Mot de passe"
            },
            navigate: {
                resetPassword: "Mot de passe oublié ?",
                signup: "En créer un"
            },
            placeholders: {
                email: "example@gmail.fr"
            },
            submit: "Se connecter"
        },
        signup: {
            alreadyHaveAccount: "J'ai déjà un compte ! ",
            didYouMeanToType: "Voulez-vous saissir",
            questionMark: " ?",
            errors: {
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire",
                passwordNotStrong: "Le mot de passe doit contenir au moins 6 caractères et un chiffre",
                passwordRequired: "Le mot de passe est nécessaire",
                termsMustBeAccepted: "L'acceptation des CGU et la politique de confidentialité est nécessaire"
            },
            labels: {
                email: "E-mail",
                password: "Mot de passe",
                terms: "J'accepte les CGU et la politique de confidentialité"
            },
            navigate: {
                signin: "Se connecter"
            },
            placeholders: {
                email: "exemple@gmail.fr",
                password: "Au moins 6 caractères et un chiffre"
            },
            submit: "Créer mon compte"
        },
        updateAccount: {
            chooseAvatarButton: "Choisir son avatar",
            errors: {
                descriptionTooLong: "La description doit contenir %{limit} caratères maximum",
                emailNotValid: "L'e-mail n'est pas valide",
                emailRequired: "L'e-mail est nécessaire",
                emailSmtp: "Il semble y avoir une erreur avec cette adresse e-mail. Merci de la vérifier.",
                nicknameRequired: "Le nom d'utilisateur est nécessaire",
                nicknameTooLong: "Le nom d'utilisateur doit contenir %{limit} caratères maximum"
            },
            labels: {
                email: "E-mail",
                description: "Description",
                location: "Lieu",
                nickname: "Nom d'utilisateur"
            },
            placeholders: {
                email: "exemple@gmail.fr",
                description: "Quelque chose à propos de vous",
                location: "Où partagez-vous vos livre ?",
                nickname: "Michel Dupont"
            }
        },
        userKeyword: {
            labels: {
                name: "Nom"
            },
            placeholders: {
                name: "Nom du mot clé"
            },
            errors: {
                nameRequired: "Le nom est nécessaire",
                nameTooLong: "Le nom doit contenir %{limit} caratères maximum",
                nameTooShort: "Le nom doit contenir %{limit} caratères minimum"
            }
        }
    },
    general: {
        areFollowing: "Abonné",
        books: {
            one: "livre",
            other: "livres",
            zero: "livres"
        },
        you: "vous",
        follow: "Suivre",
        followers: "Abonnés",
        following: "Abonnements",
        noCover: "Pas de couverture",
        requestSent: "Demande envoyée",
        share: "J'utilise la bibliothèque collaborative MyBlio. Crée un compte gratuitement et commence à partager vos livres %{url}",
        upload: "Envoyer",
        viewLess: "Voir moins",
        viewMore: "Voir plus",
        yourself: "Vous"
    },
    group: {
        add: "Créer un groupe",
        invitation: {
            accept: "Accepter",
            invite: "Inviter",
            onThe: "le ",
            refuse: "Refuser",
            youWereInvited: "Vous êtes invité à rejoindre ce groupe "
        },
        isAdmin: "Admin",
        isMember: "Membre",
        join: "Rejoindre",
        request: {
            accept: "Accepter",
            cancel: "Annuler la demande",
            onThe: "le ",
            refuse: "Ignorer",
            userRequestedToJoin: "Demande à rejoindre ",
            youRequestedToJoin: "Vous avez demandé à rejoindre ce groupe "
        },
        usage: {
            business: "Professionnel",
            education: "Éducation",
            family: "Famille",
            nonProfit: "Association",
            other: "Autre",
            personal: "Particulier",
            religious: "Religieux"
        }
    },
    list: {
        add: "Ajouter une liste",
        borrowed: {
            description: "Books that you've borrowed using MyBlio are automatically added here. You can also add books that you've borrowed from else where, like a library.",
            name: "Livres empruntés"
        },
        lent: {
            description: "Books that you've lent using MyBlio are automatically added here.",
            name: "Livres prêtés"
        },
        possessed: {
            name: "J'ai"
        },
        read: {
            name: "Lu"
        },
        reading: {
            name: "Je lis"
        },
        toRead: {
            name: "A lire"
        },
        unread: {
            name: "Pas lu"
        },
        visibility: {
            followers: "Abonnés",
            private: "Privé",
            public: "Public"
        },
        wish: {
            description: "Les livres que vous souhaiteriez avoir un jour",
            name: "Liste d'envie"
        }
    },
    menus: {
        add: {
            book: "Ajouter un livre",
            list: "Ajouter une liste",
            shelf: "Ajouter une étagère"
        },
        book: {
            addRemoveFromList: "Ajouter/retirer d'une liste(s)",
            borrow: "Emprunter",
            cancelBorrowRequest: "Annuler demande d'emprunt",
            delete: "Supprimer",
            hide: "Masquer",
            lend: "Signaler un prêt",
            modify: "Modifier",
            removeFromGroup: "Retirer du groupe",
            removeFromList: "Retirer de la liste",
            return: "Signaler le retour",
            show: "Démasquer"
        },
        bookCover: {
            chooseImg: "Choisir une image",
            generate: "Générer une couverture",
            takePhoto: "Prendre une photo"
        },
        bookLend: {
            contact: "Contacter",
            requestReturn: "Demander le retour",
            viewBook: "Voir le livre",
            viewUser: "Voir l'utilisateur"
        },
        group: {
            header: {
                titles: {
                    books: "Livres",
                    members: "Membres",
                    collections: "Collections"
                }
            },
            addRemoveBooks: "Ajouter/retirer vos livres",
            cancelJoinRequest: "Annuler demande",
            delete: "Supprimer",
            inviteToJoin: "Inviter à rejoindre ce groupe",
            leave: "Quitter le groupe",
            modify: "Modifier",
            share: "Options de partage"
        },
        groupCollection: {
            addRemoveBooks: "Ajouter/retirer des livres",
            delete: "Supprimer",
            modify: "Modifier"
        },
        list: {
            delete: "Supprimer",
            modify: "Modifier"
        },
        shelf: {
            delete: "Supprimer",
            modify: "Modifier"
        },
        user: {
            addAdmin: "Désigner admin",
            cancelFollowRequest: "Annuler la demande",
            contactUser: "Contacter",
            editYourProfile: "Modifier mon profil",
            follow: "Suivre",
            removeAdmin: "Retirer des admins",
            removeFromGroup: "Retirer du groupe",
            requestFollow: "Envoyer une demande",
            unfollow: "Se désabonner",
            viewUserProfile: "Voir l'utilisateur",
            viewYourProfile: "Voir mon profil"
        }
    },
    modal: {
        cta: {
            cancel: "Annuler",
            save: "Enregistrer"
        }
    },
    notifs: {
        emailSmtpError: {
            title: "Il semble y avoir une erreur avec votre adresse e-mail",
            description: "Merci de la vérifier en allant sur Mon profile > Modifier mon profil"
        },
        generic: {
            title: "Oups !",
            description: "Il semble qu'il y ait une erreur. Merci de recommencer plus tard."
        },
        imageNotRecognised: {
            title: "Image non reconnue",
            description: "Essayez à nouveau avec une autre image"
        },
        noCameraAccess: {
            title: "Accès refusé à l'appareil photo",
            description: "Merci d'autoriser MyBlio à accéder à votre appareil photo"
        },
        noInternet: {
            title: "Problème de connexion",
            description: "Il semble que vous ne soyez pas connecté·e à internet"
        },
        noPhotoAccess: {
            title: "Accès refusé à vos photos",
            description: "Merci d'autoriser MyBlio à accéder à vos photos"
        },
        notAuthorised: {
            title: "Action impossible",
            description: "Vous n'êtes pas connecté·e"
        }
    },
    screens: {
        account: {
            title: "Compte",
            changePassword: "Changer de mot de passe",
            deleteAccount: "Supprimer son compte",
            downloadData: "Télécharger ses données",
            subscriptions: "Abonnements",
            memberSince: "Membre depuis :"
        },
        accountProduct: {
            title: "Détail de l'offre"
        },
        accountProductCheckoutSuccess: {
            title: "Confirmation d'abonnement",
            cancelAnytime: "Vous pouvez annuler votre abonnement à tout moment.",
            productSelected: "Votre offre actuelle est la suivante :",
            subscriptionStarted: "Votre abonnement est actif",
            closeModal: "Fermer"
        },
        activity: {
            added: "a ajouté",
            booksListTitle: {
                one: "%{count} livre ajouté par %{name}",
                other: "%{count} livres ajouté par %{name}",
                zero: "Livres ajouté par %{name}"
            },
            groupCollectionBooksListTitle: {
                one: "%{count} livre ajouté à %{name}",
                other: "%{count} livres ajouté à %{name}",
                zero: "Livres ajouté à %{name}"
            },
            earlier: "Plus tôt",
            empty: {
                title: "Aucune activité",
                addBooks: "Ajouter vos livres",
                bodyHasBooksAndIsFollowing: "Il ne se passe rien dans votre réseau en ce moment. Ne serait-il pas le moment de lire un livre ?",
                bodyHasBooksAndIsNotFollowing: "Il ne se passe rien dans votre réseau en ce moment. Pourquoi ne pas chercher d'autres lecteurs ?",
                bodyHasNoBooks: "Il ne se passe rien dans votre réseau en ce moment. Commencer par ajouter des livres",
                searchUsers: "Chercher des lecteurs"
            },
            finishedReading: "a fini de lire ce livre",
            followsYou: "vous suit",
            joinedGroup: "a rejoint le groupe",
            postedReview: "a publié un avis sur ce livre",
            startedReading: "a commencé à lire ce livre",
            thisMonth: "Ce mois-ci",
            thisWeek: "Cette semaine",
            toTheGroup: "au groupe",
            toTheCollection: "ajouté à la collection",
            inGroup: "dans",
            today: "Aujourd'hui",
            viewAllBooks: "Tout voir"
        },
        addBook: {
            title: "Ajout manuel",
            submit: "Ajouter"
        },
        addBookReview: {
            title: "Publier un avis",
            submit: "Publier avis"
        },
        addBookSearch: {
            title: "Chercher par mot clé",
            addManually: "Ajout manuel",
            bookAdded: "Ajouté",
            noResults: {
                title: "Aucun livre trouvé 😢",
                body: "Vous pouvez toujour l'ajouter manuellement 😉",
                addManually: "Ajout manuel"
            },
            noSearch: {
                title: "Quel livre cherchez-vous ?",
                body: "Trouver les livres pour compléter votre bibliothèque."
            },
            or: "Ou"
        },
        addGroup: {
            title: "Créer un groupe",
            submit: "Créer",
            actionProduct: "modifier"
        },
        addGroupCollection: {
            title: "Créer une collection",
            submit: "Créer"
        },
        addList: {
            title: "Ajouter une liste",
            submit: "Ajouter"
        },
        addShelf: {
            title: "Ajouter une étagère",
            submit: "Ajouter"
        },
        addUserKeyword: {
            title: "Ajouter un mot clé",
            submit: "Ajouter"
        },
        askForFeedback: {
            title: "Vos retours",
            submit: "Envoyer"
        },
        book: {
            otherOwners: {
                ownThisBook: "Possèdent ce livre",
                alsoOwnBook: "Possèdent aussi ce livre",
                viewUser: "Voir"
            },
            alsoOwnBook: "Possèdent aussi ce livre",
            description: "Résumé",
            failed: {
                title: "⚠️ Erreur",
                body: "Le livre n'a pas pu être récupéré. Essayer plus tard.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            info: {
                collection: "Collection",
                isbn: "ISBN",
                language: "Langue",
                numberOfPages: "Nombre de pages",
                publicationDate: "Date de publication",
                publisher: "Éditeur",
                code: "Cote"
            },
            modifyReviewBtn: "Modifier",
            nextReviewBtn: "Suivant",
            noDescription: "Aucune description",
            noReviewsYet: "Aucun avis pour ce livre.",
            notAllowed: {
                title: "✋ Pas authorisé·e",
                body: "Désolé ! Vous n'avez pas le droit de voir ce livre.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            notFound: {
                title: "Livre introuvable",
                body: "Il a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            notes: "Vos notes",
            previousReviewBtn: "Précédent",
            tab: {
                label: {
                    infos: "Détails",
                    reviews: "Avis"
                }
            },
            writeReviewBtn: "Ajouter un avis"
        },
        bookInLists: {
            title: "Ajouter or retirer de list(s)",
            createListButton: "Créer une liste"
        },
        bookProviderResult: {
            title: "Detail de livre",
            submit: "Ajouter ce livre"
        },
        booksInGroup: {
            title: "Ajouter/retirer vos livres",
            submit: "Enregistrer"
        },
        booksInGroupCollection: {
            title: "Ajouter/retirer des livres",
            submit: "Enregistrer"
        },
        books: {
            empty: {
                title: "Votre bibliothèque est vide !",
                body: "Commencer par ajouter des livres, c'est simple et rapide.",
                addBooks: "Ajouter vos livres"
            },
            noResults: {
                title: "Aucun livre trouvé dans votre bibliothèque",
                patternAndFilterBody: "Pour la recherche \"%{pattern}\" et filter(s) actuel(s).",
                filterBody: "Avec les filters actuels.",
                patternBody: "Pour la recherche \"%{pattern}\".",
                showAll: "Tout afficher"
            },
        },
        borrowRequest: {
            acceptRequest: "Accepter",
            cancelRequest: "Annuler la demande",
            empty: {
                accepted: {
                    title: "Aucune demande acceptée",
                    body: "Les demanes d'emprunts acceptées seront affichées ici."
                },
                all: {
                    title: "Aucune demande",
                    body: "Les demandes d'emprunts de livre seront affichées ici."
                },
                requestByMe: {
                    title: "Aucune demande par vous",
                    body: "Les demandes d'emprunts par vous seront affichées ici."
                },
                requestToMe: {
                    title: "Aucune demande d'autres membres",
                    body: "Les demandes d'emprunts de vos livres seront affichées ici."
                }
            },
            filter: {
                accepted: "Acceptée",
                all: "Toutes",
                requestByMe: "A moi",
                requestToMe: "A d'autres"
            },
            finaliseLend: "Confirmer l'échange",
            refuseRequest: "Refuser"
        },
        borrowing: {
            contactUser: "Contacter",
            indicateReturnedBook: "Indiquer le retour",
            empty: {
                current: {
                    title: "Aucun en cours d'emprunt",
                    body: "N'hésitez pas à lancer une recherche pour trouver votre prochaine lecture.",
                    searchBook: "Chercher un livre"
                },
                finished: {
                    title: "Aucun livre emprunté",
                    body: "Les livres empruntés et rendus apparaîtrons ici."
                }
            },
            filter: {
                current: "En cours",
                finished: "Terminés"
            }
        },
        changelog: {
            title: "Changements par version"
        },
        changePassword: {
            title: "Modifier mon mot de passe"
        },
        chooseAccountProduct: {
            intro: "À mesure que votre bibliothèque s'agrandit, vous pourriez avoir besoin de passer à une offre supérieur pour bénéficier de plus de capacité et de fonctionnalités supplémentaires. Voici les offres actuellement disponibles.",
            title: "Modifier votre offre",
        },
        chooseGroupProduct: {
            intro: "Les groupes sont une excellente façon de partager des livres avec d'autres personnes dans un espace pratique. Que cela soit dans un cadre professionnel ou pour une utilisation avec votre famille, vos amis ou un club de lecture, vous trouverez l'offre qui vous correspond.",
            tab: {
                label: {
                    pro: "Professionnels",
                    personal: "Particuliers"
                }
            },
            title: "Choisir l'offre groupe",
        },
        community: {
            empty: {
                title: "Rien à afficher",
                body: "Pour l'instant vous n'avez aucun abonnement et aucun abonné.",
                bodyNoFollowers: "Vous n'avez pas abonnés.",
                bodyNoFollowing: "Vous n'avez pas d'abonnements.",
                invite: "Inviter des personnes"
            },
            filter: {
                all: "Tous",
                followers: "Abonnés",
                following: "Abonnements"
            },
            inviteButton: "Inviter"
        },
        contact: {
            title: "Nous contacter"
        },
        deviceContacts: {
            title: "Inviter vos contacts",
            info: "💡 Cochez les adresses e-mails à inviter puis validez avec le bouton 👇 « Inviter »",
            submit: "Inviter",
            contactsStatus: {
                isYou: "C'est vous !",
                alreadyInvitedByYou: "Déjà invité",
                alreadyInvitedByOther: "Déjà invité",
                existingUser: "Déjà sur MyBlio",
                existingUserFollowEachOther: "Déjà sur MyBlio",
                existingUserFollowedByYou: "Déjà sur MyBlio",
                existingUserFollowingYou: "Déjà sur MyBlio",
                
            }
        },
        discussion: {
            title: "Discussion avec %{nickname}",
            read: "Lu",
            unknown: "inconnu"
        },
        discussions: {
            title: "Messages",
            discussion: {
                accountDeleted: "Compte supprimé",
                noMessage: "Pas de messages"
            },
            empty: {
                title: "Aucune discussion",
                body: "Vos discussions avec d'autres utilisateurs apparaîtront ici."
            }
        },
        favourites: {
            empty: {
                title: "Aucun favoris",
                body: "Appuyez sur l'icône ❤️ sur la page d'un livre pour l'ajouter ici."
            }
        },
        filterBooks: {
            title: "Filtres"
        },
        followRequests: {
            title: "Demandes d'abonnements",
            empty: {
                title: "Aucune demandes",
                body: "Vous devez valider les demandes abonnements car votre compte est privé."
            }
        },
        followers: {
            title: "Abonnés",
            empty: {
                title: "Pas encore d'abonnés",
                body: "Soyez le premier à suivre %{nickname}.",
                bodyPrivate: "%{nickname} n'a actuellement pas d'abonnés.",
                bodyYourself: "Les utilsateurs abonnés à vous apparaitrons ici.",
                follow: "Suivre utilisateur"
            }
        },
        following: {
            title: "Abonnements",
            empty: {
                title: "Pas abonnements",
                body: "%{nickname} ne suit actuellement aucun utilisateur.",
                bodyYourself: "Les utiliseurs que vous suiverez apparaitrons ici."
            }
        },
        group: {
            collections: {
                add: "Créer",
                info: "Vous pouvez créer des collections afin d'organiser les livres du groupe."
            },
            empty: {
                memberOrAdmin: {
                    title: "Ce groupe n'a pas encore de livres",
                    body: "Commencer à y ajouter les vôtres.",
                    addYourBooks: "Ajouter vos livres"
                },
                user: {
                    title: "Ce groupe n'a pas encore de livre",
                    body: "Rejoint ce groupe pour y ajouter les votre.",
                    bodyRequestWaiting: "Dès que votre demande soit acceptée vous pourrez ajouter vos livres.",
                    cancelJoinRequest: "Annuler votre demande",
                    joinGroup: "Demander à rejoindre"
                }
            },
            noResults: {
                title: "Aucun livre trouvé",
                body: "Pour la recherche \"%{pattern}\".",
                showAll: "Tout afficher"
            },
            failed: {
                title: "⚠️ Erreur",
                body: "Le groupe n'a pas pu être récupéré. Essayer plus tard.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            header: {
                memberCountLabel: {
                    one: "Membre",
                    other: "Membres"
                }
            },
            notFound: {
                title: "Groupe introuvable",
                body: "Il a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            onSiteInfo: {
                availableOnSite: "Disponible sur place",
                availableForSelfService: "Disponible en libre-service"
            }
        },
        groupCollection: {
            empty: {
                title: "Cette collection est vide",
                addBooks: "Ajouter des livres",
                bodyAdmin: "Commencer à y ajouter des livres.",
                bodyUser: "Aucun livre n'a été ajouté."
            },
            failed: {
                title: "⚠️ Erreur",
                body: "La collection n'a pas pu être récupéré. Essayer plus tard.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            info: {
                about: "À propos",
                ago: " il y a",
                books: {
                    one: "%{count} livre",
                    other: "%{count} livres",
                    zero: "%{count} livres"
                },
                created: " créée",
                createdBy: " créée par ",
                groupCollectionWith: "Collection de "
            },
            notFound: {
                title: "Collection introuvable",
                body: "Elle a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            }
        },
        groupMembers: {
            title: "Membres du groupe"
        },
        groupProduct: {
            title: "Détail de l'offre"
        },
        groupProductPro: {
            title: "Détail de l'offre Pro"
        },
        groupProductCheckoutSuccess: {
            title: "Confirmation d'abonnement",
            cancelAnytime: "Vous pouvez annuler votre abonnement à tout moment.",
            productSelected: "Votre offre actuelle est la suivante :",
            subscriptionStarted: "Votre abonnement est actif",
            procceedToGroup: "Voir le groupe"
        },
        groupRequests: {
            empty: {
                title: "Aucune demande",
                body: "Ici s'affichera les demandes pour rejoindre des groupes."
            }
        },
        inviteToJoinGroup: {
            title: "Inviter à rejoindre un groupe",
            multipleTitle: "Invitations multiple",
            tab: {
                empty: {
                    invited: {
                        title: "Aucune invitation",
                        body: "Les invitations envoyées qui n'ont pas été accepté apparaîtrons ici."
                    },
                    suggestions: {
                        title: "Aucune suggestion",
                        body: "Les utilisateurs que vous pourriez inviter apparaîtrons ici"
                    }
                },
                label: {
                    invited: "Invité",
                    suggestions: "Suggestions"
                }
            }
        },
        inviteUsers: {
            title: "Invite votre entourage",
            notif: {
                noContactsFound: "Nous n’avons pas trouvé de contacts"
            },
            labelAccessContacts: "Choisir parmi vos contacts",
            btnAccessContacts: "Mes contacts",
            or: "ou"
        },
        lend: {
            title: "Signaler un prêt",
            suggestedUser: {
                actionLabel: "Prêter"
            },
            suggestedUsers: {
                title: "Suggestions",
                empty: {
                    title: "Aucune suggestion",
                    body: "Les utilisateurs à qui vous pourriez prêter ce livre apparaîtrons ici."
                }
            }
        },
        lending: {
            empty: {
                current: {
                    title: "Aucun livre en cours de prêt",
                    body: "Vous pouvez indiquer les livres prêtés depuis votre bibliothèque.",
                    goToLibrary: "Voir la bibliothèque"
                },
                finished: {
                    title: "Aucun livre prêté",
                    body: "Les livres prêtés et rendus apparaîtrons ici."
                }
            },
            indicateReturn: "Indiquer le retour",
            confirmReturn: "Confirmer le retour",
            filter: {
                current: "En cours",
                finished: "Terminés"
            }
        },
        list: {
            empty: {
                title: "Cette liste est vide",
                addBooks: "Ajouter vos livres",
                bodyOwner: "Commencer à y ajouter des livres.",
                bodyPossessed: "Les livres notés comme « J'ai » apparaîtrons ici.",
                bodyRead: "Les livres notés comme « Lu » apparaîtrons ici.",
                bodyReading: "Les livres notés comme « Je lis » apparaîtrons ici.",
                bodyToRead: "Les livres notés comme « A lire » apparaîtrons ici.",
                bodyUnread: "Les livres notés comme « Pas lu » apparaîtrons ici.",
                bodyUser: "%{nickname} n'a pas encore ajouté·e de livres ici."
            },
            failed: {
                title: "⚠️ Erreur",
                body: "La liste n'a pas pu être récupéré. Essayer plus tard.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            info: {
                about: "À propos",
                ago: " il y a",
                books: {
                    one: "%{count} livre",
                    other: "%{count} livres",
                    zero: "%{count} livres"
                },
                created: " créée",
                createdBy: " créée par",
                listWith: "Liste de "
            },
            notAllowed: {
                title: "✋ Pas authorisé·e",
                body: "Désolé ! Vous n'avez pas le droit de voir cette liste.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            notFound: {
                title: "Liste introuvable",
                body: "Elle a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            searchHeader: {
                matches: {
                    one: "trouvé",
                    other: "trouvés",
                    zero: "trouvé"
                },
                showAll: "Tout afficher"
            },
        },
        lists: {
            noResults: {
                title: "Aucun livre trouvé dans vos listes",
                patternAndFilterBody: "Pour la recherche \"%{pattern}\" et filter(s) actuel(s).",
                filterBody: "Avec les filters actuels.",
                patternBody: "Pour la recherche \"%{pattern}\".",
                showAll: "Tout afficher"
            },
            seeAll: "Tout voir"
        },
        myGroups: {
            empty: {
                title: "Aucun groupe",
                body: "Vous ne faites pas encore partie d'un groupe. C'est le moment d'en créer ou d'en rejoindre !",
                createGroup: "Créer un groupe"
            }
        },
        notFound: {
            empty: {
                title: "Cette page n'existe pas",
                body: "Soit le lien est incorrect ou n'existe plus.",
                goHome: "Revenir à l'acceuil"
            }
        },
        premiumAccountOnly: {
            title: "Fonctionnalité premium",
            keywords: {
                p1: "Pour avoir accès à la fonctionnalité mots clés personnalisés il est nécessaire de souscrire à une des offres suivantes :"
            },
            lendsHistory: {
                p1: "Pour avoir accès à l'historique de ses prêts et ses emprunts il est nécessaire de souscrire à une des offres suivantes :"
            },
            iframe: {
                p1: "Pour avoir accès à la fonctionnalité iframe il est nécessaire de souscrire à une des offres suivantes :"
            },
            lendDuration: {
                p1: "Pour avoir accès à l'option durée de vos prêts il est nécessaire de souscrire à une des offres suivantes :"
            }
        },
        premiumGroupOnly: {
            title: "Fonctionnalité premium",
            collections: {
                p1: "Pour avoir accès à la fonctionnalité collection il est nécessaire de souscrire à une des offres suivantes :"
            },
            multipleAdmins: {
                p1: "Pour pouvoir ajouter plusieurs administrateurs·rices il est nécessaire de souscrire à une des offres suivantes :"
            },
            iframe: {
                p1: "Pour avoir accès à la fonctionnalité iframe il est nécessaire de souscrire à une des offres suivantes :"
            },
            selfService: {
                p1: "Pour avoir accès à la fonctionnalité emprunts libre-service il est nécessaire de souscrire à une des offres suivantes :"
            }
        },
        prePaymentAccountProduct: {
            title: "Récapitulatif",
            recap: "Plus qu'une étape !",
            productSelected: "Vous avez choisie l'offre suivante :",
            cancelAnytime: "Vous pouvez annuler votre abonnement à tout moment.",
            procceedToPayment: "Aller au paiement"
        },
        prePayementGroupProduct: {
            title: "Récapitulatif",
            groupCreated: "Le groupe a bien été créé",
            groupModified: "Le groupe est prêt",
            productSelected: "Vous avez choisie l'offre suivante :",
            cancelAnytime: "Vous pouvez annuler votre abonnement à tout moment.",
            procceedToPayment: "Aller au paiement"
        },
        product: {
            tariff: {
                free: "Gratuit"
            },
            interval: {
                month: "mois",
                year: "an",
                monthly: 'mensuel',
                yearly: 'annuel'
            },
            choose: "Choisir",
            findOutMore: "Plus d'infos",
            quotas: {
                members: "membres",
                books: "livres",
                keywords: "mots-clés",
                collections: "collections"
            },
            yourCurrentPlan: "Votre offre actuelle"
        },
        profile: {
            title: "",
            nav: {
                followRequests: "Demandes d'abonnemet",
                modifyMyAccount: "Options du compte",
                modifyMyProfile: "Modifier mon profil",
                modifyMySettings: "Modifier mes réglages",
                upgradeAccountProduct: "Passer au compte premium"
            }
        },
        scan: {
            footer: {
                title: "Scanner le code-barres",
                addManuallyBtn: "Ajout manuel",
                else: "Ou",
                searchBtn: "Par mots clés"
            },
            result: {
                addBookBtn: "Ajouter ce livre"
            }
        },
        search: {
            title: "Recherche sur MyBlio",
            submitSearch: "Chercher",
            beforeSearch: {
                title: "Saisissez votre recherche",
                body: "Chercher des livres, utilisateurs et groupes."
            },
            noBookResults: {
                title: "Aucun livre trouvé",
                body: "Pour la recherche \"%{pattern}\"."
            },
            noGroupResults: {
                title: "Aucun groupe trouvé",
                body: "Pour la recherche \"%{pattern}\"."
            },
            noUserResults: {
                title: "Aucun utilisateur trouvé",
                body: "Pour la recherche \"%{pattern}\"."
            },
            tab: {
                label: {
                    books: "Livres",
                    groups: "Groupes",
                    users: "Utilisateurs"
                }
            }
        },
        settings: {
            title: "Réglages",
            foundInSearch: {
                title: "Être trouvable par la recherche",
                description: "Permet aux autres membres (amis, collègues, famille etc.) du service à vous trouver facilement."
            },
            messagesByEmail: {
                title: "Recevoir les messages par e-mail",
                description: "Les messages envoyés depuis Myblio arriverons aussi sur votre adresse e-mail."
            },
            newsletter: {
                title: "Recevoir la newsletter",
                description: "Être informé des actualités de MyBlio sur votre adresse e-mail."
            },
            notifications: {
                title: "Recevoir des notifications",
                description: "Être notifié des événements tel que : demande d'emprunt d'un livre, abonnements et autres."
            },
            notifsByEmail: {
                title: "Recevoir des notifications par emails",
                description: "Être notifié des événements tel que : demande d'emprunt d'un livre, abonnements et autres."
            },
            languages: {
                en: "Anglais",
                fr: "Français",
                default: "Automatique"
            },
            lendDurations: {
                none: "Sans durée",
                oneWeek: "1 semaine",
                twoWeeks: "2 semaines",
                threeWeeks: "3 semaines",
                fourWeeks: "4 semaines",
            },
            private: {
                title: "Compte privé",
                description: "Seul les utilisateurs acceptés par vous pourrons voir votre compte."
            }
        },
        shareGroup: {
            title: "Partage votre groupe",
            labels: {
                "iframe-code": "Code :"
            },
            shareLinkInstructions: "Copiez-collez le lien ci-dessous pour partager votre groupe.",
            shareLinkToLibraryTitle: "Partager le lien de votre groupe",
            shareWithIframeInstructions: "Utilisez le code <iframe> ci-dessous afin d'afficher les livres de votre groupe sur votre site ou blog. Vous pouvez modifier les valeurs \"width\" et \"height\" selon l'emplacement choisi.",
            shareWithIframeTitle: "Intégrez votre groupe sur votre site",
            selfServicePosterTitle: "Affiche emprunts libre-service",
            selfServicePosterInstructions: "Cette affiche intègre un QR Code qui permet aux membres de votre groupe d'emprunter les livres disponibles en libre-service. Imprimez-la et affichez-la à l'endroit où se trouvent les livres.",
            selfServicePosterBtn: "Télécharger l'affiche",
            selfServicePosterNoBooksAvailable: "Vous n'avez pas de livres disponible en libre-service"
        },
        share: {
            title: "Partage votre bibliothèque",
            labels: {
                "iframe-code": "Code :"
            },
            noPubliclyVisibleShelves: "Aucune de vos étagères sont visible publiquement. Vous pouvez modifier ceci en changeant la visibilité à 'public' dans les réglages de chaque étagère.",
            notAllShelvesArePubliclyVisible: "Actuellement %{publiclyVisibleShelvesCount} de vos étagères sont visible publiquement. Vous pouvez modifier la visiblité dans les réglages de chaque étagère.",
            shareLinkInstructions: "Copiez-collez le lien ci-dessous pour partager votre bibliothèque. Seules les étagères et listes avec la visibilté 'public' apparaîtront",
            shareLinkToLibraryTitle: "Partager le lien de votre bibliothèque",
            shareOnSocialMediaTitle: "Partager votre bibliothèque sur les réseaux",
            shareWithIframeInstructions: "Utilisez le code <iframe> ci-dessous afin d'afficher votre bibliothèque sur votre site. Vous pouvez modifier les valeurs \"width\" et \"height\" selon l'emplacement choisi.",
            shareWithIframeTitle: "Intégrez votre bibliothèque sur votre site ou blog",
            twitter: {
                text: "Explore ma bibliothèque sur MyBlio !"
            }
        },
        shelf: {
            empty: {
                title: "Cette étagère est vide",
                addBooks: "Ajouter vos livres",
                bodyOwner: "Commencer à y ajouter des livres.",
                bodyUser: "%{nickname} n'a pas encore ajouté·e de livres ici."
            },
            failed: {
                title: "⚠️ Erreur",
                body: "L'étagère n'a pas pu être récupéré. Essayer plus tard.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            searchHeader: {
                matches: {
                    one: "trouvé",
                    other: "trouvés",
                    zero: "trouvé"
                },
                showAll: "Tout afficher"
            },
            info: {
                ago: " il y a",
                books: {
                    one: "%{count} livre",
                    other: "%{count} livres",
                    zero: "%{count} livres"
                },
                created: " créée",
                createdBy: " créée par ",
                shelfWith: "Étagère de "
            },
            notAllowed: {
                title: "✋ Pas authorisé·e",
                body: "Désolé ! Vous n'avez pas le droit de voir cette étagère.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            notFound: {
                title: "Étagère introuvable",
                body: "Elle a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            }
        },
        shelves: {
            empty: {
                title: "Votre bibliothèque est vide !",
                body: "Commencer par ajouter des livres, c'est simple et rapide.",
                addBooks: "Ajouter vos livres"
            },
            noResults: {
                title: "Aucun livre trouvé dans vos étagères",
                patternAndFilterBody: "Pour la recherche \"%{pattern}\" et filter(s) actuel(s).",
                filterBody: "Avec les filters actuels.",
                patternBody: "Pour la recherche \"%{pattern}\".",
                showAll: "Tout afficher"
            },
            seeAll: "Tout voir"
        },
        subscriptions: {
            title: "Abonnements",
            cancelled: "Votre abonnement a été annulé et prendra fin le %{date}",
            currentPeriod: "Période en cours du %{from} au %{to}",
            modify: "Modifier",
            month: "mois",
            year: "année",
            empty: {
                title: "Aucun abonnement trouvé",
                body: "Vous n'avez pas abonnement actif."
            }
        },
        takePhoto: {
            title: "Prendre une photo",
            retake: "Reprendre",
            use: "Utiliser"
        },
        terms: {
            title: "Terms and conditions"
        },
        updateBook: {
            title: "Modifier livre",
            submit: "Enregistrer"
        },
        updateBookReview: {
            title: "Modifier avis",
            submit: "Enregistrer"
        },
        updateGroup: {
            title: "Modifier groupe",
            submit: "Enregistrer",
            actionProduct: "modifier"
        },
        updateGroupCollection: {
            title: "Modifier collection",
            submit: "Enregistrer"
        },
        updateList: {
            title: "Modifier liste",
            submit: "Enregistrer"
        },
        updateProfile: {
            title: "Modifier profil"
        },
        updateShelf: {
            title: "Modifier étagère",
            submit: "Enregistrer"
        },
        updateShelfShareBooksGroup: {
            title: "Options de l'étagère",
            submit: "Enregistrer"
        },
        updateUserKeyword: {
            title: "Modifier mot clé",
            submit: "Enregistrer",
            delete: "Supprimer mot clé",
            booksUsingKeyword: {
                one: "Ce mot clé est utilisé sur un livre.",
                other: "Ce mot clé est utilisé sur %{count} livres.",
                zero: "Ce mot clé n'a pas encore été utilisé."
            }
        },
        user: {
            cancelRequest: "Annuler demande",
            requestSent: "Demande envoyé",
            noPublicLists: {
                title: "Aucune liste à afficher",
                body: "%{nickname} n'a pas de liste visible publiquement."
            },
            noPublicShelves: {
                title: "Aucune étagère à afficher",
                body: "%{nickname} n'a pas d'étagère visible publiquement."
            },
            notFound: {
                title: "Utilisateur introuvable",
                body: "Il a peut-être été supprimé ou le lien est incorrect.",
                goBack: "Revenir à la page précédente",
                goHome: "Aller à l'acceuil"
            },
            private: "Ce compte est privé",
            requestFollow: "Envoyer une demande",
            tab: {
                label: {
                    lists: "Listes",
                    shelves: "Étagères"
                }
            }
        },
        welcome: {
            gotoLoginScreen: "Se connecter",
            gotoSignupScreen: "Créer un compte"
        }
    },
    sharing: {
        book: {
            email: {
                owner: {
                    body: "Le voir ici : %{url}",
                    subject: "Voir le livre '%{name}' sur MyBlio"
                },
                user: {
                    body: "Le voir ici : %{url}",
                    subject: "Voir le livre '%{name}' sur MyBlio"
                }
            },
            native: {
                owner: "Voir le livre '%{name}' sur MyBlio %{url}",
                user: "Voir le livre '%{name}' sur MyBlio %{url}"
            },
            twitter: {
                owner: "Voir le livre '%{name}'",
                user: "Voir le livre '%{name}'"
            }
        },
        group: {
            email: {
                owner: {
                    body: "Voir le groupe ici : %{url}",
                    subject: "Voir mon groupe '%{name}' sur MyBlio"
                },
                user: {
                    body: "Voir le groupe ici : %{url}",
                    subject: "Voir le groupe '%{name}' sur MyBlio"
                }
            },
            native: {
                owner: "Voir mon groupe '%{name}' sur MyBlio %{url}",
                user: "Voir le groupe '%{name}' sur MyBlio %{url}"
            },
            twitter: {
                owner: "Voir mon groupe '%{name}'",
                user: "Voir le groupe '%{name}'"
            }
        },
        groupCollection: {
            email: {
                user: {
                    body: "Voir cette collection ici : %{url}",
                    subject: "Voir la colllection :  '%{name}' sur MyBlio"
                }
            },
            native: {
                user: "Voir la collection '%{name}' sur MyBlio %{url}"
            },
            twitter: {
                user: "Voir la collection '%{name}'"
            }
        },
        inviteUrl: {
            native: {
                user: "J'utilise la bibliothèque collaborative MyBlio. Rejoins-moi et partageons nos livres gratuitement ! %{url}"
            }
        },
        list: {
            email: {
                owner: {
                    body: "La voir ici : %{url}",
                    subject: "Voir ma liste de livres '%{name}' sur MyBlio"
                },
                user: {
                    body: "La voir ici : %{url}",
                    subject: "Voir la liste de livres '%{name}' sur MyBlio"
                }
            },
            native: {
                owner: "Voir '%{name}' ma liste de livres sur MyBlio %{url}",
                user: "Voir '%{name}' une liste de livres sur MyBlio %{url}"
            },
            twitter: {
                owner: "Voir '%{name}' ma liste de livres",
                user: "Voir '%{name}' une liste de livres"
            }
        },
        shelf: {
            email: {
                owner: {
                    body: "La voir ici : %{url}",
                    subject: "Voir mon étagère de livres '%{name}' sur MyBlio"
                },
                user: {
                    body: "La voir ici : %{url}",
                    subject: "Voir cette étagère de livres '%{name}' sur MyBlio"
                }
            },
            native: {
                owner: "Voir '%{name}' mon étagère de livres sur MyBlio %{url}",
                user: "Voir '%{name}' cette étagère de livres sur MyBlio %{url}"
            },
            twitter: {
                owner: "Voir '%{name}' mon étagère de livres",
                user: "Voir '%{name}' cette étagère de livres"
            }
        },
        user: {
            email: {
                owner: {
                    body: "Les voir ici : %{url}",
                    subject: "Voir mes livres 📚 sur MyBlio"
                },
                user: {
                    body: "Les voir ici : %{url}",
                    subject: "Voir les livres 📚 de (%{name}) sur MyBlio"
                }
            },
            native: {
                owner: "Voir mes livres 📚 sur MyBlio %{url}",
                user: "Voir les livres 📚 de (%{name}) sur MyBlio %{url}"
            },
            twitter: {
                owner: "Voir mes livres 📚",
                user: "Voir les livres 📚 de (%{name})"
            }
        }
    },
    shelf: {
        add: "Ajouter une étagère",
        visibility: {
            followers: "Abonnés",
            private: "Privé",
            public: "Public"
        }
    },
    sidebar: {
        addButton: "Ajouter",
        credit: "Fait à Bordeaux avec ♥️",
        logout: "Se déconnecter",
        navItems: {
            contact: "Nous-contacter",
            findOutMore: "Plus d'infos",
            followRequests: "Demandes",
            messages: "Messages",
            profile: "Mon profil",
            settings: "Réglages",
            share: "Partager"
        },
        public: {
            about: "Utilise MyBlio pour gérer sa bibliothèque, trouver d'autres lecteurs et créer des groupes.",
            login: "Se connecter",
            or: "ou",
            signup: "Créer un compte"
        },
        welcome: "Bienvenue,"
    },
    tabs: {
        groups: {
            header: {
                title: "Groupes"
            },
            tabBarLabel: "Groupes",
            tabs: {
                groupRequests: {
                    title: "Demandes"
                },
                myGroups: {
                    title: "Les miens"
                },
                publicGroups: {
                    title: "Publics"
                }
            }
        },
        home: {
            header: {
                title: "Accueil"
            },
            tabBarLabel: "Accueil",
            tabs: {
                activity: {
                    title: "Activité"
                },
                community: {
                    title: "Communauté"
                }
            }
        },
        lends: {
            header: {
                title: "Échanges"
            },
            tabBarLabel: "Échanges",
            tabs: {
                bookRequests: {
                    title: "Demandes"
                },
                borrowing: {
                    title: "Emprunts"
                },
                lending: {
                    title: "Prêts"
                }
            }
        },
        library: {
            header: {
                title: "Ma bibliothèque"
            },
            tabBarLabel: "Bibliothèque",
            tabs: {
                books: {
                    title: "Livres",
                    tabs: {
                        all: {
                            title: "Tous"
                        },
                        lists: {
                            title: "Listes"
                        },
                        shelves: {
                            title: "Étagères"
                        }
                    }
                },
                favourites: {
                    title: "Favoris"
                },
            }
        }
    },
    user: {
        description: {
            none: "Bientôt une description"
        },
        followRequest: {
            accept: "Accepter",
            refuse: "Refuser"
        },
        followsYou: "Vous suit",
        location: {
            unknown: "Inconnu"
        },
        notFound: "[Compte supprimé]",
        echelon: {
            premium: "Premium",
            basic: "Gratuit"
        }
    }
}