import React, { useMemo, useCallback, memo, useEffect, useContext, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { selectLending, selectLent, selectAccount } from '../../selectors';
import { getLendsApi, getLendsInProgress } from '../../actions/getLends';
import { updateUnreadNotifsApi } from '../../actions/updateUnreadNotifs';
import { useIsLoading, useUnreadNotifs } from '../../hooks';
import LendsList from '../../components/LendsList';
import Empty from '../../components/Empty';
import HorizontalFilters from '../../components/HorizontalFilters';
import { sortByDate, handleUnreadNotifsDone, isWeb } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.lending';

const filters = [
    { key: 'current', label: 'filter.current' },
    { key: 'finished', label: 'filter.finished' }
]

function LendingScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('current');
    const [refreshing, setRefreshing] = useState(false);
    const { unreadLendReturnsIndicatedByBorrower, unreadConsiergeBorrows } = useUnreadNotifs();
    const lending = useSelector(selectLending);
    const account = useSelector(selectAccount);
    const lent = useSelector(selectLent);
    const isGettingLends = useIsLoading(getLendsInProgress);
    const hasLendsHistoryFeature = account?.features?.lendsHistory;
    const filteredLends = useMemo(() => {
        if(filter == 'finished') return lent;
        return lending;
    }, [lending, lent, filter]);
    const data = useMemo(() => sortByDate(filteredLends, 'created'), [filteredLends])

    const renderEmpty = useMemo(() => (
        <Empty 
            title={t(`${tBase}.empty.${filter}.title`)} 
            body={t(`${tBase}.empty.${filter}.body`)}
            img={ require('../../assets/noLending.png') }
            primaryActionLabel={ filter === 'current' ? t(`${tBase}.empty.${filter}.goToLibrary`) : ''}
            primaryActionOnPress={ filter === 'current' ? () => navigation.navigate('LibraryTab') : null } />      
    ), [filter]);

    const handleFilterChange = (value) => {
        if(value === 'finished' && ['basic', 'legacy'].includes(account.echelon) && isWeb()) {
            navigation.navigate('Modal', { screen: 'PremiumAccountOnly', params: { feature: 'lendsHistory' } })
            return;
        }
        setFilter(value)
    }

    const renderHeader = useMemo(() => (
        <View style={{ paddingVertical: 15, paddingRight: 15, justifyContent: 'center', backgroundColor: '#fff' }}>
            <HorizontalFilters 
                tBase={tBase}
                filters={filters} 
                selected={filter} 
                onSelect={handleFilterChange} />
        </View> 
        
    ), [filter]);

    const getLends = (checkCaching=true, showRefreshing, callback=() => {}) => {
        if(!isGettingLends) {
            showRefreshing && setRefreshing(true);
            dispatch(getLendsApi({ showLoading: false, checkCaching })).then(() => {
                setRefreshing(false);
                callback();
            });
        }
    }

    const onRefresh = useCallback(() => getLends(false, true), []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', handleOnFocus);
        return unsubscribe;
    }, [unreadLendReturnsIndicatedByBorrower, unreadConsiergeBorrows]);

    const handleOnFocus = () => {
        const shouldCheckCache = (() => {
            const lendingIds = lending.map(({ id }) => id);
            if(unreadLendReturnsIndicatedByBorrower.length) {
                for(let lendReturnIndicatedByBorrowerId of unreadLendReturnsIndicatedByBorrower) {
                    const lend = lending.find(({ id }) => id === lendReturnIndicatedByBorrowerId);
                    if(!lend || lend?.returnIndicatedByBorrowerDatetime === null) {
                         return false;
                    }
                }
            }
            if(unreadConsiergeBorrows.length) {
                for(let unreadConsiergeBorrow of unreadConsiergeBorrows) {
                    if(!lendingIds.includes(unreadConsiergeBorrow)) return false;
                }
            }
            return true;
        })();
        const updateUnreadNotifs = () => {
            if(unreadLendReturnsIndicatedByBorrower.length) {
                dispatch(updateUnreadNotifsApi({ notifsRead: { lendReturnsIndicatedByBorrower: unreadLendReturnsIndicatedByBorrower } }))
            }
            if(unreadConsiergeBorrows.length || unreadLendReturnsIndicatedByBorrower.length) {
                const notifsRead = {};
                if(unreadConsiergeBorrows.length) notifsRead.consiergeBorrows = unreadConsiergeBorrows;
                if(unreadLendReturnsIndicatedByBorrower.length) notifsRead.lendReturnsIndicatedByBorrower = unreadLendReturnsIndicatedByBorrower;
                dispatch(updateUnreadNotifsApi({ notifsRead })).then(handleUnreadNotifsDone)
            }
        }
        getLends(shouldCheckCache, false, updateUnreadNotifs);
    }
    const showHeader = (lending.length || lent.length) && (isWeb() || hasLendsHistoryFeature);
    return (
        <LendsList 
            data={data}
            navigation={navigation}
            useStickyHeader={true}
            contentContainerStyle={showHeader ? { paddingTop: 0 } : { paddingTop: 15 }}
            listHeaderStyle={showHeader ? { paddingBottom: 10 } : {} }
            onRefresh={onRefresh}
            refreshing={refreshing}
            renderHeader={showHeader ? renderHeader : undefined}
            renderEmpty={renderEmpty}
        />
    )
}

export default memo(LendingScreen)