import React, { useEffect, useState, useContext } from 'react';
import { View, TextInput, Pressable, StyleSheet, Platform } from 'react-native';
import { navigation } from '../navigation/RootNavigation';
import { GRAY_LIGHT, SECONDARY_COLOR, PLACEHOLDER_TEXT_COLOR, DANGER_COLOR, GRAY_TEXT_PALE } from '../constants';
import { MaterialIcons } from '@expo/vector-icons';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import TextLink from './TextLink';
import FormError from './FormError';
import Button from './Button';
import InputSuggestion from './InputSuggestion';
import { isNative } from '../utils';
import { Ionicons } from '@expo/vector-icons';

import AppSettingsContext from '../context/AppSettingsContext';

import Animated, { useAnimatedStyle, interpolateColor, useSharedValue, FadeIn, FadeOut } from 'react-native-reanimated';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);


export default function Input({ 
        inputProps, 
        errors={}, 
        tBase, 
        inputPrefix,
        showFormErrors=true, 
        hasPlaceholder=true, 
        showDescription=false,
        showSubmitButton=false,
        showFieldWarningIcon=false,
        suggestionText,
        Suggestion=null,
        inputIcon={}, 
        charLimit, 
        submit,
        inputHeight, 
        containerStyle={}, 
        inputStyle={},
        showLabel=true,
        onFocus=() => {} }) {
    const {t} = useContext(AppSettingsContext);
    const valueLength = useSharedValue(inputProps.value ? parseInt(inputProps.value.length) : 0);
    const charLimitStyle = useAnimatedStyle(() => {
        if(!charLimit) return {}
        const color = interpolateColor(
            valueLength.value,
            [charLimit-(charLimit * 0.15), charLimit],
            ['#898989', '#BF2932']
        );
        return { color };
    });
    useEffect(() => {
        if(charLimit != undefined) {
            valueLength.value = inputProps.value ? parseInt(inputProps.value.length) : 0;
        }
    }, [inputProps.value])
    const autoCorrectFalse = ['email', 'nickname', 'author', 'websiteUrl'];
    const autoCapitalizeNone = ['email', 'password', 'websiteUrl'];
    const [showFocus, setShowFocus] = useState(false);
    const [prefixWidth, setPrefixWidth] = useState(0);
    const keyboardType = (() => {
        if(inputProps.name == 'email') return 'email-address';
        if(inputProps?.keyboardType) return inputProps.keyboardType;
        return 'default';
    })()
    const secureTextEntry = inputProps.name.toLowerCase().indexOf('password') > -1 ? true : false;
    const height = (() => {
        if(inputHeight) return inputHeight 
        return inputProps.multiline ? 78 : 45;
    })()
    const clearButtonMode = inputProps.clearButtonMode ? inputProps.clearButtonMode : 'while-editing'; 
    const autoCorrect = (() => {
        if(inputProps.autoCorrect !== undefined) return inputProps.autoCorrect;  
        return autoCorrectFalse.includes(inputProps.name) ? false : true;
    })()
    const autoCapitalize = inputProps?.autoCapitalize ?? autoCapitalizeNone.includes(inputProps.name) ? 'none' : 'sentences';
    const labelText = t(`${tBase}.labels.${inputProps.name}`);
    const shouldShowLabel = showLabel && !!labelText;
    const descriptionText = t(`${tBase}.descriptions.${inputProps.name}`);
    const editable = inputProps.editable ?? true;
    const handleResetPasswordPress = () => navigation.navigate('ResetPassword');
    const handleFocus = () => {
        setShowFocus(true);
        onFocus();
    }
    const onPressClear = () => {
        inputProps.onChange && inputProps.onChange('')
    }
    const showClearButton = showFocus && !!inputProps.value && clearButtonMode == 'while-editing' && !inputProps.multiline && isNative();
    const { Icon } = inputIcon;
    const onPrefixLayout = ({ nativeEvent: { layout: { width }}}) => !prefixWidth && setPrefixWidth(width);
    const showSuggestion = Suggestion || suggestionText;
    return (
        <View style={containerStyle}>
            { shouldShowLabel &&
            <View style={ styles.top }>
                { shouldShowLabel &&
                <View style={{ flexDirection: 'row' }}>
                    <MontSBold text={labelText} style={styles.label} />
                    { !editable && <MaterialIcons name='lock' style={{ marginLeft: 5, marginTop: 2 }} color={SECONDARY_COLOR} /> }
                    { showFieldWarningIcon && <MaterialIcons name='warning' style={{ marginLeft: 5, marginTop: 1 }} size={ 14 } color="orange" /> }
                </View>
                }
                <View style={{ marginLeft: 'auto' }}>
                    { tBase.indexOf('signin') > -1 && inputProps.name == 'password' ?
                        <Pressable onPress={handleResetPasswordPress}>
                            <TextLink text={ t(`forms.signin.navigate.resetPassword`) } style={styles.passwordReset} /> 
                        </Pressable>
                        : null
                    }
                    { charLimit && 
                        <Animated.Text style={[charLimitStyle, { fontFamily: 'Inter_400Regular' } ]}>{ charLimit - (inputProps.value ? inputProps.value.length : 0) }</Animated.Text>
                    }
                </View>
            </View>
            }
            { showDescription && <InterReg text={descriptionText} style={styles.description} /> }
            <View style={{ height, flexDirection: showSubmitButton ? 'row' : 'column' }}>
                <View style={{ flex: 1 }}>
                    { Icon && 
                        <View style={[styles.inputIcon, { height }]}>
                            <Icon 
                                name={inputIcon.name} 
                                color={inputIcon.color || GRAY_TEXT_PALE} 
                                size={inputIcon.size || 15} />
                        </View> 
                    }
                    { inputPrefix && 
                        <View onLayout={ onPrefixLayout } style={[styles.inputPrefix, { height }]}>
                            <InterReg style={{ color: SECONDARY_COLOR }} text={inputPrefix} />
                        </View>
                    }
                    <TextInput
                        onFocus={handleFocus}
                        style={[
                            styles.input,
                            showFocus && { borderColor: SECONDARY_COLOR },
                            errors[inputProps.name] && { borderColor: DANGER_COLOR },
                            inputProps.multiline && { textAlignVertical: 'top' },
                            { height },
                            inputStyle,
                            { paddingRight: showClearButton ? 35 : 12 },
                            { paddingLeft: Icon ? 30 : 12 },
                            inputPrefix && { paddingLeft: prefixWidth }
                        ]}
                        {...inputProps}
                        clearButtonMode='never'
                        value={ inputProps.value ? inputProps.value + '' : '' }
                        keyboardType={keyboardType}
                        secureTextEntry={secureTextEntry}
                        autoCorrect={autoCorrect}
                        autoCapitalize={autoCapitalize}
                        editable={editable}
                        onChange={ null }
                        onBlur={ () => setShowFocus(false) }
                        placeholder={ hasPlaceholder ? t(`${tBase}.placeholders.${inputProps.name}`) : '' }
                        placeholderTextColor={ PLACEHOLDER_TEXT_COLOR }
                        onChangeText={ (value) => {
                            if(value && keyboardType == 'numeric') value = parseInt(value); 
                            inputProps.onChange && inputProps.onChange(value); 
                        }} 
                    />
                    { showClearButton &&
                        <AnimatedPressable 
                            entering={FadeIn.duration(400)}
                            exiting={FadeOut.duration(400)}
                            onPress={onPressClear}
                            style={[styles.clearButton, { height }]}>
                            <Ionicons name="close-circle" size={20} color='#aaa' />
                        </AnimatedPressable>
                    }
                </View>
                { showSubmitButton && <Button size='medium' buttonStyle={ styles.submitButton } text={t(`${tBase}.submit`)} onPress={submit} />}
            </View>
            { showSuggestion && <InputSuggestion>{ Suggestion }</InputSuggestion> }
            { showFormErrors && <FormError error={ errors[inputProps.name] }/> }
        </View>
    )
}

const styles = StyleSheet.create({
    top: {
        paddingBottom: 6,
        paddingHorizontal: 6,
        flexDirection: 'row'
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 13,
        color: SECONDARY_COLOR
    },
    description: {
        marginTop: -2,
        marginBottom: 8,
        paddingHorizontal: 6,
    },
    input: {
        ...Platform.select({
            web: {
            outlineStyle: 'none',
            transitionDuration: '200ms',
            },
            default: {},
        }),
        backgroundColor: GRAY_LIGHT,
        paddingVertical: 8, 
        paddingHorizontal: 12, 
        fontSize: 15,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: GRAY_LIGHT,
        fontFamily: 'Inter_400Regular'
    },
    passwordReset: {
        fontSize: 13,
        paddingBottom: 2,
        color: '#666'
    },
    clearButton: {
        position: 'absolute', 
        right: 0, 
        width: 35, 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    inputIcon: {
        position: 'absolute',
        width: 35,
        zIndex: 1,
        justifyContent: 'center', 
        alignItems: 'center', 
    },
    inputPrefix: {
        position: 'absolute',
        paddingLeft: 12,
        zIndex: 1,
        justifyContent: 'center'
    },
    submitButton: {
        marginLeft: 8,
        marginBottom: 0
    }
})

