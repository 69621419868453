import React, { useState, useEffect, memo, useCallback } from 'react';
import { Pressable } from 'react-native';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import Avatar from './Avatar';
import BookCoverMenu from './BookCoverMenu';
import { useUser } from '../hooks';
import { navigateToBook, isNative, navigateToUser } from '../utils';

function Book({ bookId, title, author, ownerId, width, navigation, isFocused, context, contextId, marginHorizontal, rememberPressedBookId, listId, shelfId, groupId, groupSlug }) {
    const [showMenu, setShowMenu] = useState(true);
    const params = (() => {
        const params = { bookId };
        if(listId) return { ...params, listId }
        if(groupId) return { ...params, groupId, groupSlug }
        return params;
    })()
    useEffect(() => {
        if(isFocused && !showMenu && isNative()) setShowMenu(true);
    }, [isFocused]);
    const handleBookPress = useCallback(() => {
        navigateToBook(navigation, params);
        if(isNative()) {
            setShowMenu(false);
            rememberPressedBookId(bookId);
        }   
    }, [])
    const showAddedBy = groupId || context == 'groupCollection';
    return (
        <Pressable 
            style={{ flex: 1, width, minWidth: width, maxWidth: width, marginHorizontal }} 
            onPress={handleBookPress}>
            <BookCoverMenu 
                context={context} 
                contextId={contextId} 
                navigation={navigation}
                bookId={bookId} 
                shelfId={shelfId}
                listId={listId} 
                groupId={groupId} 
                coverWidth={width} 
                showMenu={showMenu} />
            <MontSBold numberOfLines={1} style={{ marginTop: 7, color: '#545454' }} text={ title } />
            { !!author &&
                <InterReg numberOfLines={1} style={{ marginTop: 1, fontSize: 13, color: '#545454' }} text={author} />
            }
            { showAddedBy && <Owner ownerId={ownerId} navigation={navigation} /> }
        </Pressable>
    )
}

const Owner = memo(({ ownerId, navigation }) => {
    const owner = useUser(ownerId);
    const handleOwnerPress = useCallback(() => navigateToUser(navigation, { userId: owner?.id }), []);
    if(!owner) return null;
    return (
        <Pressable onPress={handleOwnerPress} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
            <Avatar avatar={owner.avatar} blurHash={owner.blurHash} avatarSize={ 20 } />
            <InterReg numberOfLines={1} style={{ marginLeft: 5, fontSize: 13, color: '#545454' }} text={owner.nickname} />
        </Pressable>
    )
})

export default memo(Book, (prevProps, nextProps) => {
    if(prevProps.title != nextProps.title || prevProps.author != nextProps.author || prevProps.width != nextProps.width) {
        return false;
    }
    return (
        (prevProps.isFocused == nextProps.isFocused) || 
        (prevProps.isFocused != nextProps.isFocused && prevProps.bookId != nextProps.pressedBookId)
    );
});