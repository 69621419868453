import React, { useContext } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Feather, MaterialIcons as MIcons, Ionicons } from '@expo/vector-icons';
import { MAIN_COLOR, GRAY_LINE_COLOR, SECONDARY_COLOR, OFF_WHITE, SUCCESS_COLOR } from '../constants';
import { confirmRequestFollowUser, confirmAcceptRequestFollowUser, confirmRefuseRequestFollowUser } from '../utils/alerts';
import { requestFollowUserApi } from '../actions/requestFollowUser';
import { followUserApi } from '../actions/followUser';
import { getUnreadNotifsApi } from '../actions/getUnreadNotifs';
import { acceptFollowUserRequestApi } from '../actions/acceptFollowUserRequest';
import { refuseFollowUserRequestApi } from '../actions/refuseFollowUserRequest';
import { isEven, hasRequestedFollowUser, getFollowRequestToMeFromUser, handleUnreadNotifsDone } from '../utils';
import { useGroup, useFollowRequestsForUser } from '../hooks';
import Location from './Location';
import Avatar from './Avatar';
import Button from './Button';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import MenuUser from './MenuUser';
import IsStatus from './IsStatus';
import AppSettingsContext from '../context/AppSettingsContext';

export default function UserListItem({ 
        user, 
        onUserPress, 
        numColumns, 
        itemWidth,
        marginHorizontal,
        navigation, 
        context='list', 
        index,
        route, 
        compact=false, 
        lastItem
    }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const groupId = route?.params?.groupId;
    const group = useGroup(groupId);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const followRequests = useFollowRequestsForUser(user.id);
    const isYourself = user.id == loggedUserId;
    const isFollowing = user.followerIds?.includes(loggedUserId);
    const isGroupAdmin = group ? group.adminIds.includes(user.id) : null;
    const isFollowed = user.followingIds?.includes(loggedUserId);
    const hasRequested = hasRequestedFollowUser(followRequests, user.id, loggedUserId);
    const followRequestToMeFromUser = getFollowRequestToMeFromUser(followRequests, user.id, loggedUserId);
    const {
        id,
        avatar,
        nickname,
        isPrivate,
        bookCount,
        description,
        location,
    } = user;
    const avatarSize = compact ? 40 : 50;
    const handleFollow = () => { 
        if(isPrivate) {
            confirmRequestFollowUser(() => dispatch(requestFollowUserApi({ userId: id })), dispatch, { nickname })
        } else {
            dispatch(followUserApi({ userId: id })) 
        }
    }
    const handleAcceptFollowRequest = () => { confirmAcceptRequestFollowUser(acceptFollowRequest, dispatch, { nickname }) }
    const handleRefuseFollowRequest = () => {confirmRefuseRequestFollowUser(refuseFollowRequest, dispatch, { nickname }) }
    const acceptFollowRequest = () => { dispatch(acceptFollowUserRequestApi({ followRequestId: followRequestToMeFromUser.id }) ).then(doneRespondToFollowRequest) }
    const refuseFollowRequest = () => { dispatch(refuseFollowUserRequestApi({ followRequestId: followRequestToMeFromUser.id }) ).then(doneRespondToFollowRequest) }
    const showFollowBtn = !hasRequested && !isFollowing && !isYourself && context !== 'groupMembers' && context != 'followRequests';
    const showCancelRequestBtn = hasRequested && context !== 'groupMembers' && context != 'followRequests';
    const showMoreMenu = context != 'followRequests';
    const showIsFollowing = isFollowing && context != 'followRequests';
    const showIsYourself = isYourself && context != 'followRequests';
    const computedStyles = {
        width: numColumns > 1 ? itemWidth : '100%',
        maxWidth: numColumns > 1 ? itemWidth : '100%',
        minWidth: numColumns > 1 ? itemWidth : '100%',
        marginHorizontal: numColumns > 1 ? marginHorizontal : 0,
        backgroundColor: isEven(index) || numColumns > 1 ? OFF_WHITE : '#fff',
        borderBottomWidth: lastItem || numColumns > 1 ? StyleSheet.hairlineWidth : 0, 
        borderBottomColor: lastItem || numColumns > 1 ? GRAY_LINE_COLOR: 'transparent'
    }
    const doneRespondToFollowRequest = (json) => {
        if(json?.success) {
            dispatch(getUnreadNotifsApi({})).then(handleUnreadNotifsDone);
        }
    }
    return (
        <TouchableOpacity 
            activeOpacity={0.8}
            style={ [styles.container, numColumns > 1 && styles.multiColumn, computedStyles ] }
            onPress={ () => onUserPress(user) }>
            <View style={[{ width: avatarSize }, styles.left]}>
                { isGroupAdmin && <View style={ styles.adminBadge }><Ionicons color='white' name='md-star' size={ 10 } /></View> }
                <Avatar avatarSize={ avatarSize } blurHash={user.blurHash} avatar={ avatar } /> 
                { !compact ?  
                    <View style={ styles.librarySize }>
                        <MIcons name='book' size={17} color={ MAIN_COLOR } /> 
                        <InterReg style={styles.bookNumber} text={ bookCount } />
                    </View> : null 
                }
            </View>
            <View style={ styles.center }>
                <View style={{flexDirection: 'row' }}>
                    { isPrivate && <MIcons name='lock' style={{ marginTop: 2, marginRight: 5 }} size={14} color={SECONDARY_COLOR} /> }
                    <MontSBold
                        text={ nickname }
                        numberOfLines={ 1 }
                        style={styles.name} />
                </View>
                <Location location={ location } />
                { !compact ? <InterReg style={ styles.description } numberOfLines={ 2 } text={description} /> : null }
            </View>
            <View style={ [styles.right] }>
                { showIsFollowing && <IsStatus text={ t('general.areFollowing') } /> }
                { showIsYourself && <IsStatus text={ t('general.yourself') } /> }
                { context == 'followRequests' ? 
                    <View>
                        <Button 
                            text={t('user.followRequest.accept')} 
                            size='xSmall'
                            buttonStyle={{ marginBottom: 7, paddingHorizontal: 8 }}
                            onPress={ handleAcceptFollowRequest } />
                        <Button 
                            text={t('user.followRequest.refuse')} 
                            theme='grayColorBorder'
                            size='xSmall'
                            buttonStyle={{ paddingHorizontal: 8 }} 
                            onPress={ handleRefuseFollowRequest } />
                    </View>
                    : null 
                }
                { showCancelRequestBtn && 
                    <View style={{ flexDirection: 'row', marginTop: 2, alignItems: 'flex-end'}}>
                        <Ionicons name="checkmark-circle" size={16} color={SUCCESS_COLOR}  />
                        <InterReg style={{ fontSize: 13, marginLeft: 4 }} text={ t(`general.requestSent`) } />
                    </View>
                }
                { showFollowBtn &&
                    <Button 
                        text={ t(`general.follow`) } 
                        buttonStyle={{ marginBottom: 0, paddingHorizontal: 8 }}
                        size='xSmall'
                        iconName='md-person-add' 
                        onPress={ handleFollow } /> 
                }

            </View>
            { showMoreMenu &&
            <MenuUser 
                user={user}
                group={group}
                context={context} 
                navigation={navigation}
                style={{position: 'absolute', bottom: 10, right: 15}}
                TriggerButton={() => (
                    <View style={styles.more} pointerEvents='none'>
                        <Feather name="more-horizontal" size={20} color="#7f7f7f" />
                    </View>
                )} />
            }
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', 
        paddingHorizontal: 15,
        paddingVertical: 13,
        flex: 1,
        borderTopWidth: StyleSheet.hairlineWidth, 
        borderTopColor: GRAY_LINE_COLOR,
    },
    avatarContainer: {
        backgroundColor: 'lightgray', 
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center'
    },
    adminBadge: {
        position: 'absolute',
        top: -2,
        left: -2,
        borderColor: 'white',
        borderWidth: 1,
        width: 19,
        height: 19,
        borderRadius: 10,
        backgroundColor: MAIN_COLOR,
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    location: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    librarySize: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8
    },
    bookNumber: {
        marginLeft: 5,
        fontSize: 14,
        color: SECONDARY_COLOR
    },
    name: {
        fontSize: 15,
        marginBottom: 5,
        flex: 1,
        color: SECONDARY_COLOR
    },
    description: {
        fontSize: 14,
        paddingTop: 3,
        color: '#333'
    },
    left: {
        alignItems: 'center'
    },
    center: {
        flex: 1,
        flexShrink: 1,
        paddingLeft: 15
    },
    right: {
        paddingLeft: 15
    },
    more: { 
        width: 24, 
        height: 24, 
        borderRadius: 12, 
        backgroundColor: '#e2e2e2', 
        alignItems: 'center', 
        justifyContent: 'center' 
    },
    multiColumn: { 
        borderRadius: 8,
        borderWidth: StyleSheet.hairlineWidth, 
        borderColor: GRAY_LINE_COLOR, 
        padding: 12, 
        paddingBottom: 8, 
        backgroundColor: '#fafafa'
    }
})