import { combineReducers } from 'redux';
import { users } from './users';
import { books } from './books';
import { lists } from './lists';
import { shelves } from './shelves';
import { keywords } from './keywords';
import { loggedUserId } from './loggedUserId';
import { settings } from './settings';
import { loading } from './loading';
import { alert } from './alert';
import { bookProviderResults } from './bookProviderResults';
import { generatedCovers } from './generatedCovers';
import { borrowRequests } from './borrowRequests';
import { lends } from './lends';
import { handledNotifs } from './handledNotifs';
import { followRequests } from './followRequests';
import { discussions } from './discussions';
import { messages } from './messages';
import { groupRequests } from './groupRequests';
import { groupInvitations } from './groupInvitations';
import { groupCollections } from './groupCollections';
import { groupBookAttributes } from './groupBookAttributes';
import { groups } from './groups';
import { caching } from './caching'; 
import { bookLikes } from './bookLikes';
import { bookReviews } from './bookReviews';
import { tags } from './tags';
import { search } from './search';
import { activity } from './activity';
import { addedBooksHistory } from './addedBooksHistory';
import { groupProducts } from './groupProducts';
import { accountProducts } from './accountProducts';
import { unreadNotifs } from './unreadNotifs';
import { userKeywords } from './userKeywords';

export default combineReducers({
    bookProviderResults,
    borrowRequests,
    lends,
    generatedCovers,
    users,
    books,
    lists,
    shelves,
    keywords,
    loggedUserId,
    settings,
    loading,
    alert,
    handledNotifs,
    followRequests,
    discussions,
    messages,
    groupRequests,
    groupInvitations,
    groupCollections,
    groupBookAttributes,
    groups,
    caching,
    bookLikes,
    bookReviews,
    tags,
    search,
    activity,
    addedBooksHistory,
    groupProducts,
    accountProducts,
    unreadNotifs,
    userKeywords
});