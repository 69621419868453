import React, { memo} from 'react';
import { View } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { SECONDARY_COLOR } from '../constants';

function MenuTrigger({ style={}, iconColor='#fff', iconSize=19 }) {
    return (
        <View style={[{ height: 22, width: 22, borderRadius: 11, alignItems: 'center', justifyContent: 'center', backgroundColor: SECONDARY_COLOR }, style]}>
            <Feather name="more-horizontal" size={iconSize} color={iconColor} />
        </View>
    )
}

export default memo(MenuTrigger)