import { createReducer } from '@reduxjs/toolkit'
import { getAccountProductsSuccess } from '../actions/getAccountProducts';
import { matchResetStore } from './matchers';

const initialState = [];

export const accountProducts = createReducer(initialState, (builder) => {
    builder
    .addCase(getAccountProductsSuccess, (state, action) => {
        const {products} = action.payload;
        return products;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})